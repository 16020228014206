import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('OCMapButton',_vm._g({},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VSheet,{staticClass:"rounded-lg",staticStyle:{"min-width":"200px"}},[_c(VList,{staticClass:"rounded-lg",attrs:{"dense":""}},[_vm._t("prepend"),_c('div',{staticClass:"px-3 mt-2"},[_vm._v("Подложка:")]),_c(VListItemGroup,{attrs:{"value":_vm.preference,"color":"primary","mandatory":""},on:{"change":_vm.select}},_vm._l((_vm.mapStyles),function(val,key){return _c(VListItem,{key:key,staticClass:"pl-6",attrs:{"dense":"","value":key}},[_vm._v("– "+_vm._s(val))])}),1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }