<template>
  <div class="d-flex map-page overflow-hidden" style="height: calc(100vh - 64px); height: calc(100dvh - 56px);" :style="{'--map-controls-bottom-offset': bottomSheetHeight + 'px'}">
    <div
        class="info-panel-header-container"
    >
      <OCMapBottomSheetCollapsed
          v-if="$vuetify.breakpoint.smAndDown"
          @open="showInfoPanel = true"
          :current-item="currentItem"
          :item="item"
          :selected-items="selectedItems"
          :total-items="totalItems"
          :height.sync="bottomSheetHeight"
      />
    </div>
    <component :is="$vuetify.breakpoint.mdAndUp ? VExpandXTransition() : 'div'">
      <div
          class="info-panel-container"
          :class="showInfoPanel ? 'active' : ''"
          @click.self="showInfoPanel = false"
          v-if="showInfoPanel"
      >
        <v-btn
            class="info-panel__panel-toggle px-1 py-6 rounded-r"
            @click="showInfoPanel = false"
            tile
            depressed
        ><v-icon size="24">mdi-chevron-left</v-icon></v-btn
        >
        <v-sheet class="info-panel" @touchstart="touchStartPanel">
          <v-progress-linear
              indeterminate
              style="min-height:4px"
              v-if="cardLoading"
          />
          <OCObjectDataCard
              @filter="
              $event.type = 'object';
              $event.name = $event.title;
              options.zone = $event;
              options.category = null;
              options.type = null;
              options.query = null;
              item = null;
            "
              v-else-if="item && !showLayerEdit"
              :value="item.id"
              @input="item = $event"
          />
          <template v-else-if="selectedItems && selectedItems.length && !showLayerEdit">
            <div class="d-flex align-center px-4 my-3">
              <div>
                Выбрано объектов: <b>{{ selectedItems.length }}</b>
              </div>
              <v-spacer />
              <v-icon @click="selectedItems = []">mdi-close</v-icon>
            </div>
            <v-divider />
            <v-list class="overflow-y-auto scrollable-content">
              <template v-for="(item, i) in selectedItems">
                <OCObjectListItem
                    @click="navigateTo(item)"
                    :key="item.id"
                    :item="item"
                />
                <v-divider :key="i + 'd'" />
              </template>
            </v-list>
          </template>
          <template v-else>
            <div class="info-panel__nav d-flex align-center pa-2 px-4">
              <div class="font-weight-bold text-h6">Объекты на модерации</div>
              <v-spacer />
            </div>
            <div class="info-panel__content scrollable-content" v-if="showLayerEdit">
              <OCLayerEditor :options="options" @filters="filters = $event" style="min-height: calc(100% - 72px)" v-if="showLayerEdit" @close="showLayerEdit = false" v-model="options.types" #default="{ search, updateSearch }">
                <div class="mb-3 d-flex">
                  <v-text-field
                      placeholder="Найти слой..."
                      prepend-inner-icon="mdi-magnify"
                      dense
                      hide-details
                      :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-4'"
                      class="grey custom-field-border-behavior"
                      outlined
                      type="search"
                      clearable
                      :value="search"
                      @input="updateSearch"
                  >
                    <template v-slot:append>
                      <v-badge
                          offset-y="8"
                          :value="filtersSize"
                          :content="filtersSize.toString()"
                          v-if="$vuetify.breakpoint.smAndDown"
                      >
                        <v-icon
                            @click.prevent.stop="showFilters = true" @mouseup.stop
                        >mdi-tune-variant</v-icon>
                      </v-badge>
                    </template>
                  </v-text-field>
                  <v-btn
                      @click="showLayerEdit = false"
                      height="40"
                      depressed
                      :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-4'"
                      class="grey ml-2 custom-button-border-behavior"
                  >
                    <v-icon size="24" left>mdi-format-list-bulleted</v-icon>
                    Объекты
                  </v-btn>
                </div>
                <div class="d-flex flex-row-reverse mt-n2 mr-n2">
                  <v-chip color="transparent" @click:close="options.advanced = false" :close="options.advanced" @click="showLayerEdit = false">Расширенный режим</v-chip>
                </div>
                <div class="overflow-x-auto flex-shrink-1 mx-n3 mt-2 d-md-none" v-if="filtersSize > -1">
                  <OCMapFilters hide-toggle class="px-2" style="width: max-content" variant v-model="options" @open="filters = $event" />
                </div>
              </OCLayerEditor>
              <div class="pa-3" style="position: sticky; bottom: 0; left: 0; right: 0;">
                <v-btn height="40" :loading="listLoading" block @click="showLayerEdit = false" color="primary">Показать объекты <div class="pa mx-2 rounded" style="">{{ totalItems }}</div></v-btn>
              </div>
            </div>
            <div class="info-panel__content scrollable-content" v-else>
              <div v-if="category">
                <v-sheet
                    class="ma-2 mx-3 pa-2 d-flex align-center rounded-lg"
                    rounded
                    dark
                    color="success"
                    elevation="0"
                >
                  <div class="" v-text="category.title"></div>
                  <v-spacer />
                  <v-icon @click="category = topic = null">mdi-close</v-icon>
                </v-sheet>
                <v-sheet
                    class="ma-2 mx-3 pa-2 d-flex align-center rounded-lg"
                    dark
                    color="success"
                    elevation="0"
                    v-if="topic"
                >
                  <div class="" v-text="topic.title"></div>
                  <v-spacer />
                  <v-icon @click="topic = null">mdi-close</v-icon>
                </v-sheet>
              </div>
              <div class="px-3 my-3 d-flex">
                <v-text-field
                    type="search"
                    clearable
                    v-model="options.query"
                    placeholder="Найти..."
                    prepend-inner-icon="mdi-magnify"
                    dense
                    hide-details
                    :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-4'"
                    class="grey custom-field-border-behavior"
                    outlined
                >
                  <template v-slot:append>
                    <v-badge
                        offset-y="8"
                        :value="filtersSize"
                        :content="filtersSize"
                        v-if="$vuetify.breakpoint.smAndDown"
                    >
                      <v-icon @click="showFilters = true"
                              @click.prevent.stop="showFilters = true" @mouseup.stop
                      >mdi-tune-variant</v-icon>
                    </v-badge>
                  </template>
                </v-text-field>
                <v-badge offset-x="20" offset-y="14" :content="layerCount || '0'"
                         v-if="options.advanced"
                >
                  <v-btn
                      @click="showLayerEdit = true"
                      height="40"
                      depressed
                      :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-4'"
                      class="grey ml-2 custom-button-border-behavior"
                  >
                    <v-icon size="24" left>mdi-layers-outline</v-icon>
                    Слои
                  </v-btn>
                </v-badge>

              </div>
              <div class="d-flex my-2 mt-n2 mx-1">
                <v-spacer/>
                <v-chip color="transparent" @click:close="options.advanced = false" :close="options.advanced" @click="options.advanced = true; showLayerEdit = true">{{options.advanced ? 'Расширенный режим' : 'Расширенный режим' }}</v-chip>
              </div>
              <div class="overflow-x-auto flex-shrink-1 mx-n0 d-md-none" v-if="filtersSize > -1">
                <OCMapFilters hide-toggle class=" px-2" style="width: max-content" variant v-model="options" @open="filters = $event" />
              </div>
              <div class="info-panel__category-grid px-2" v-if="!category && !options.advanced">
                <category-card
                    @click="category = cat"
                    color="#4CAF50"
                    class="pa-2 px-2 justify-start"
                    v-for="cat in OCClassifier"
                    :key="cat.title"
                >
                  <span
                      class="category-card__color mt-3"
                      style="width: 40px"
                      v-html="cat.icon.value"
                      v-if="cat.icon.svg"
                  ></span>
                  <v-icon
                      class="category-card__color"
                      size="40"
                      v-text="cat.icon"
                      v-else
                  ></v-icon>
                  <div
                      class="text-center font-weight-medium"
                      style="line-height: normal"
                      v-text="cat.title"
                  ></div>
                </category-card>
              </div>
              <v-list dense v-if="category && !topic && !options.advanced">
                <template v-for="(top, i) in category.objectTypes">
                  <v-list-item
                      class="px-3 py-2"
                      @click="topic = top"
                      :key="'i' + top.id"
                  >
                    {{ top.title }}
                    <v-spacer />
                    <span v-text="topicStats[top.id] || ''" />
                  </v-list-item>
                  <v-divider :key="i" />
                </template>
              </v-list>
              <v-progress-linear indeterminate v-if="listLoading">
              </v-progress-linear>
              <template v-else>
                <div class="px-3 my-2">
                  Найдено: <b>{{ totalItems }}</b>
                </div>
                <template v-for="(item2, i) in listItems">
                  <OCObjectListItem
                      :key="'i' + item2.id"
                      :item="item2"
                      @click="navigateTo(item2)"
                  />
                  <v-divider :key="i" />
                </template>
                <div
                    class="pa-3 d-flex align-center"
                    v-if="!listLoading && totalPages > 1"
                >
                  <v-btn
                      width="36"
                      :class="options.page == 1 && 'hidden'"
                      @click="options.page -= 1"
                      @contextmenu.prevent="options.page = 1"
                      :disabled="options.page == 1"
                      x-small
                      height="36"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-spacer />
                  <span>
                    {{ (options.page - 1) * options.itemsPerPage + 1 }} -
                    {{
                      Math.min(options.page * options.itemsPerPage, totalItems)
                    }}
                    из
                    {{ totalItems }}
                  </span>
                  <v-spacer />
                  <v-btn
                      x-small
                      :class="options.page == totalPages && 'hidden'"
                      @click="options.page += 1"
                      @contextmenu.prevent="options.page = totalPages"
                      :disabled="options.page == totalPages"
                      width="36"
                      height="36"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </template>
            </div>
          </template>
        </v-sheet>
      </div>
    </component>
    <div class="flex-grow-1">
      <OCMap
          large-map
          ref="map"
          :options="options"
          :coords="coords"
          :zoom.sync="zoom"
          :activeItems.sync="activeItems"
      >
        <template #controls-bottom>
          <div class="overflow-x-auto flex-shrink-1 d-md-none mx-n1">
            <OCMapFilters style="width: max-content" class="ma-1 mr-1" v-model="options" @open="filters = $event" />
          </div>
        </template>
        <template v-slot:controls-top>
          <div class="d-flex align-center">
            <OCMapButton class="d-md-none" @click="showInfoPanel = true; options.advanced = true; showLayerEdit = true">
              <v-badge
                  primary
                  offset-y="6"
                  offset-x="7"
                  bordered
                  :value="options.advanced"
                  :content="layerCount || '0'"
              >
                <v-icon>mdi-layers-outline</v-icon>
              </v-badge>
            </OCMapButton>
            <v-btn
                tile
                :color="!$vuetify.theme.dark && 'white'"
                @click="toggleInfoPanel"
                class="map-controls__panel-toggle rounded-lg px-1 py-3 d-none d-md-flex"
            >
              <v-icon
                  size="24"
                  v-text="showInfoPanel ? 'mdi-chevron-left' : 'mdi-chevron-right'"
              />
            </v-btn>
            <OCMapLocationSearch class="mr-0" v-if="showLocationSearch" :expanded.sync="locationSearchExpanded" style="max-width: 420px;" foldable />
          </div>
          <div v-show="!locationSearchExpanded" class="flex-shrink-1 d-none d-md-flex align-center">
            <OCMapFilters class="mx- flex-wrap flex-shrink-1" v-model="options" @open="filters = $event" />
          </div>
          <v-spacer />
          <OCMapButton
              exact
              :to="listRoute"
          >
            <v-icon>
              mdi-format-list-bulleted
            </v-icon>
          </OCMapButton>
          <OCMapSettings>
            <template v-slot:prepend>
                <v-list-item class="pl-3" dense exact :to="listRoute">
                  <v-icon dense left>mdi-format-list-bulleted</v-icon>
                  Показать списком
                </v-list-item>
                <v-list-item class="pl-3" dense exact @click="showLocationSearch = !showLocationSearch">
                  <v-icon dense left>mdi-magnify</v-icon>
                  Поиск по адресу
                  <v-icon right :class="{hidden: !showLocationSearch}" class="pl-5 ml-auto">mdi-check</v-icon>
                </v-list-item>
              <v-divider />
            </template>
          </OCMapSettings>
        </template>

        <template v-slot:controls-right>
          <v-spacer />
          <OCMapGeolocate />
          <OCMapZoom />
          <v-spacer />
        </template>
      </OCMap>
    </div>
    <v-fade-transition v-if="$vuetify.breakpoint.smAndDown">
      <div
          class="map-dim"
          @click="showInfoPanel = false"
          v-show="showInfoPanel"
      ></div>
    </v-fade-transition>
    <v-bottom-sheet
        transition="dialog-bottom-transition"
        v-model="showFilters"
        v-if="true"
        scrollable
    >
      <v-card style="border-top-left-radius: 12px; border-top-right-radius: 12px">
        <v-card-title class="pa-0 py-md-3">
          <v-container class="mb-0 pb-0">
            <div class="d-flex">
              <h3>Настройки</h3>
              <v-spacer />
              <v-btn icon @click="filters = false" large
              ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </v-container>
        </v-card-title>
        <v-card-text class="pa-0" style="max-height: 80vh">
          <v-container class="mt-0 py-0">
            <OCFilterBlock
                :type.sync="filters"
                zone
                @update:options="updateOptions"
                :options="options"
                :filters="options.dataFilters"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import CategoryCard from "../../components/CategoryCard.vue";
import opencity from "../../service/opencity";
import PanelFrameVue from "../../components/PanelFrame/PanelFrame.vue";
import OCObjectDataCard from "@/components/object/OCObjectDataCard";
import opencityService from "@/service/opencity";
import {datetime2} from "@/utils/datetime";

import OCMap from "@/components/map/OCMap";
import OCMapButton from "@/components/map/OCMapButton";
import OCMapSettings from "@/components/map/OCMapSettings";
import OCMapGeolocate from "@/components/map/OCMapGeolocate";
import OCMapZoom from "@/components/map/OCMapZoom";
import OCObjectListItem from "@/components/object/OCObjectListItem";
import OCMapLocationSearch from "@/components/map/OCMapLocationSearch.vue";
import OCFilterBlock from "@/components/OCFilterBlock";
import useClassifier from "@/mixins/useClassifier";
import {filterOptionsToQueryObject} from "@/utils/filterOptions";
import OCLayerEditor from "@/components/OCLayerEditor.vue";
import {VExpandXTransition,} from "vuetify/lib/components";
import OCMapFilters from "@/components/OCMapFilters.vue";
import {defaultFilterDefinitions} from "@/assets/filters";
import OCMapBottomSheetCollapsed from "@/views/Objects/OCMapBottomSheetCollapsed.vue";

function defaultOptions() {
  return {
    types: [],
    author: null,
    advanced: false,
    page: 1,
    itemsPerPage: 20,
    query: "",
    type: null,
    category: null,
    region: null,
    visibility: null,
    myObjects: false,
    created: {
      from: null,
      to: null
    },
    updated: {
      from: null,
      to: null
    },
    list: null,
    dataFilters: {},
    variants: {
      1: 0
    },
    'tag~1,crown_diam': {
      from: null,
      to: null
    },
    'tag~1,trunk_diam': {
      from: null,
      to: null
    },
    'tag~1,height': {
      from: null,
      to: null
    },
    'tag~1,damage': {
      has: null
    },
    'tag~14,damage': {
      has: null
    },
    emptyDataFilters: () => ({
      species: null,
      "genus.name": null,
      condition: null,
      '1,crown_diam': {
        from: null,
        to: null
      },
      '1,trunk_diam': {
        from: null,
        to: null
      },
      '1,height': {
        from: null,
        to: null
      },
      '1,damage': {
        has: null
      }
    }),
  };
}

export default {
  name: "ObjectsMap",
  filters: { datetime2 },
  data: () => ({
    advanced: false,
    locationSearchExpanded: false,
    showLegend: false,
    filters: false,
    coords: { lng: 56.169770480103284, lat: 58.01933146095297 },
    showLayerEdit: false,
    mapOptions: {
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: "greedy",
      autocompleteSessionToken: null
    },
    options: defaultOptions(),
    cardLoading: false,
    listLoading: false,
    totalItems: null,
    listItems: [],
    selectedItems: [],
    currentItem: null,
    zoom: 13,
    regions: null,
    previousPage: 0,
    statsInternal: null,
    updateFromWithin: false,
    showLocationSearch: false,
    bottomSheetHeight: 0,
  }),
  watch: {
    item: {
      immediate: true,
      handler (val) {
        if (this.currentItem?.id != val.id) {
          this.currentItem = null;
          opencity.getObject(val.id).then(res => {
            this.currentItem = res;
          });
        }
      }
    },
    topic(val, oldVal) {
      if (oldVal === null) return;
      if (val?.id !== oldVal.id) this.options.dataFilters = this.options.emptyDataFilters();
    },
    "options.zone": {
      immediate: true,
      deep: true,
      handler(val, prev) {
        const zone = val;
        if (zone?.type === "region") {
          if (prev?.id !== Number(zone.id) || prev.type !== "region")
            opencity.getRegions().then(regions => {
              const on = regions.find(rg => rg.id === Number(zone.id));
              if (!on) return;
              on.type = "region";
              this.options.zone = on;
              this.$refs.map.focusGeometry(on.borders);
            });
        }
        if (zone?.type === "object") {
          if (prev?.id !== Number(zone.id) || prev.type !== "object")
            opencity.getObject(zone.id).then(el => {
              // el.type = "object";
              // el.name = el.title;
              // options.region = el;
              el.type = "object";
              this.options.zone = el;
              if (!this.item) this.$refs.map.focusItem(el, { animate: false });
            });
        }
      }
    },
    "options.advanced": {
      handler: function (val, prev) {
        if (!!val !== !!prev) {
          if (val) {
            if (this.item || this.activeItems?.length) {

            } else {
              this.showLayerEdit = true;
            }
            this.options.type = null;
            this.options.category = null;
          } else {
            this.showLayerEdit = false;
            this.options.types = null;
          }
        }
      }
    },
    options: {
      deep: true,
      immediate: true,
      handler: function(val, prev) {
        if (val.page === this.previousPage) {
          val.page = 1;
        }
        if (prev) this.updateQueryStr();
        this.previousPage = val.page;
        this.fetchItemPage();
      }
    },
    "$route.query"(val, prev) {
      if (JSON.stringify(val) === JSON.stringify(prev)) return;
      if (this.updateFromWithin) return;
      this.restoreFromQueryStr(val);
    }
  },
  computed: {
    filtersSize() {
      let filters = [];
      const o = this.options;
      for (let key in o) {
        const def = defaultFilterDefinitions[key];
        if (!def || def.isEmpty(o[key])) continue;

        const textContent = defaultFilterDefinitions[key].makeDescription(o[key]);
        let r = key.replace('tag~', '');
        let type = r.match(/^(\d+),/)?.[1];
        filters.push({ title: textContent, key: key, type: type ? Number(type) : true });
      }
      return filters.length;
    },
    showFilters: {
      get() {
        return !!this.filters;
      },
      set(val) {
        if (this.filters && val) return;
        this.filters = val;
      }
    },
    topicStats() {
      if (this.statsInternal) return this.statsInternal;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      opencity
          .get("/objects/stats")
          .then(res => res.json())
          .then(data => {
            this.statsInternal = data.reduce((o, el) => {
              o[el[0]] = el[1];
              return o;
            }, {});
          });
      return {};
    },
    listRoute() {
      return {
        path: "/service/moderation",
        query: this.$route.query
      };
    },
    activeItems: {
      get() {
        if (this.item) return [this.item];
        else if (this.selectedItems) return this.selectedItems;
        else return null;
      },
      set(val) {
        if (val?.length) {
          this.showLayerEdit = false;
        }
        if (!val?.length) {
          this.item = null;
          this.selectedItems = [];
        } else if (val.length === 1) this.item = val[0];
        else {
          this.item = null;
          this.selectedItems = val;
          this.showInfoPanel = true;
        }
      }
    },
    showInfoPanel: {
      get() {
        if (this.$route.hash === "#closed") {
          return false;
        }
        return true;
      },
      set(val) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.$router.replace({
            path: this.$route.fullPath,
            hash: val ? "#" : "#closed"
          });
        } else {
          this.$router.push({
            path: this.$route.fullPath,
            hash: val ? "#" : "#closed"
          });
        }
        setTimeout(() => this.$refs.map?.$refs.map?.map?.resize(), 400);
      }
    },
    item: {
      get() {
        if (this.$route.query?.item) {
          return { id: Number(this.$route.query.item) };
        }
        return null;
      },
      set(val) {
        const old = this.showInfoPanel;
        this.$router.push({ query: { ...this.$route.query, item: val?.id } });
        if (this.$vuetify.breakpoint.mdAndUp)
          this.showInfoPanel = old || !!val;
        else
          this.showInfoPanel = old;
      }
    },
    layerCount() {
      return this.options.types?.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.options.itemsPerPage);
    },
    category: {
      get() {
        return this.options.category;
      },
      set(val) {
        this.options.category = val;
      }
    },
    topic: {
      get() {
        return this.options.type;
      },
      set(val) {
        this.options.type = val;
      }
    },
    filtersActive() {
      return false;
    }
  },
  mixins: [useClassifier],
  methods: {
    touchStartPanel(event) {
      let touchStartX = event.touches[0].clientX;
      let touchStartY = event.touches[0].clientY;
      const element = event.currentTarget;
      // let touchStartScrollTop = event.currentTarget.scrollTop;

      let close = false;
      let flag = false;
      let activate = false;
      event.currentTarget.querySelectorAll('.scrollable-content').forEach(el => {
        if (el.contains(event.target) && !(el.scrollTop === 0)) flag = true;
      });
      if (flag) return;

      const onTouchMove = event => {
        let dy = event.touches[0].clientY - touchStartY;

        close = dy > 100;
        if (dy > 0 && !activate) {
          if (!event.cancelable) {
            onTouchEnd(event);
          } else {
            activate = true;
          }
        }
        if (activate) {
          event.currentTarget.style = `transform: translateY(${Math.max(dy, 0)}px)`;
          event.preventDefault();
        }
      };

      const onTouchEnd = event => {
        if (close) {
          this.showInfoPanel = false;
        }

        element.style = "";
        element.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("touchend", onTouchEnd);
      };

      event.currentTarget.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchend", onTouchEnd);
    },
    VExpandXTransition() {
      return VExpandXTransition
    },
    updateOptions(opt) {
      this.filters = false;
      this.options = opt ?? defaultOptions();
      this.updateQueryStr();
    },
    navigateTo(item) {
      this.selectedItems = [];
      this.item = item;
      this.$refs.map.focusItem(item);
    },
    fetchItemPage() {
      this.loading = true;
      const {
        page,
        itemsPerPage,
        query,
        zone,
        category,
        type
      } = this.options;
      let region = zone;

      let types;
      if (this.options.advanced && this.options.types) {
        types = this.options.types;
      } else if (type) {
        types = [type.id];
      } else if (category) {
        types = category.objectTypes.map(el => el.id);
      }

      let parent = null;
      let childRegions = null;
      if (region?.type === "region") {
        childRegions = region?.children?.map(el => el.id);
        if (childRegions) childRegions.push(region.id);
        else childRegions = [region.id];
      } else if (region?.type === "object") {
        parent = region.id;
      }

      this.listLoading = true;

      const createdUpdatedFromTo = [
        this.options.created.from,
        this.options.created.to,
        this.options.updated.from,
        this.options.updated.to
      ];

      const author = this.options.author;
      const visibility = this.options.visibility;

      const list = this.options.list;

      let dataFilters2 = Object.entries(this.options).reduce((o, el) => {
        if (!el[0].startsWith("tag~")) return o;
        return {
          ...o,
          [el[0].replace("tag~", "")]: el[1]
        };
      }, {});

      const lastCall = (this.listPromise = opencityService
        .listObjects(
          itemsPerPage,
          page - 1,
          "updatedAt,desc",
          query,
          types,
          childRegions,
          dataFilters2,
          parent,
          createdUpdatedFromTo,
          author,
          visibility,
          list,
          false
        )
        .then(page => {
          if (lastCall !== this.listPromise) return;
          this.listItems = page.content;
          this.totalItems = page.totalElements;
          this.listLoading = false;
        }));
    },
    toggleInfoPanel(val) {
      if (val === true || val === false) this.showInfoPanel = val;
      else this.showInfoPanel = !this.showInfoPanel;
    },
    updateQueryStr() {
      let query = filterOptionsToQueryObject(this.options);
      let cQuery = this.$route.query;

      this.updateFromWithin = true;
      this.$router.replace({
        query: { item: cQuery?.item, ...query },
        hash: this.$route.hash
      });
      this.$nextTick(() => this.updateFromWithin = false);
    },
    restoreFromQueryStr(query) {
      let options = this.options;
      options.moderated = false;
      options.query = query.q;
      options.category = query.cat && JSON.parse(
          JSON.stringify(
              this.OCClassifier.find(el => el.id === Number(query.cat))
          ));
      options.type = options.category?.objectTypes.find(
          el => el.id === Number(query.type)
      );
      options.types = query.types?.split(',').map(Number);
      options.advanced = query.advanced ?? false;
      Object.entries(defaultFilterDefinitions).forEach(([key, def]) => {
        this.$set(options, key, def.deserialize(query[key]));
      });
    }
  },
  components: {
    OCMapBottomSheetCollapsed,
    OCMapFilters,
    OCLayerEditor,
    OCFilterBlock,
    OCMapLocationSearch,
    OCObjectListItem,
    OCMapZoom,
    OCMapGeolocate,
    OCMapSettings,
    OCMapButton,
    OCMap,
    OCObjectDataCard,
    CategoryCard
  },
  created() {
    this.$emit("update:layout", PanelFrameVue);
    opencity.getRegions().then(reg => (this.regions = reg));
  },
  mounted() {
    const { query } = this.$route;
    query && this.restoreFromQueryStr(query);
    if (this.item) {
      opencity.getObject(this.item.id).then(item => {
        const map = this.$refs.map?.map;
        if (this.$refs.map) {
          this.$refs.map.focusItem(item, { animate: false });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.map-page {
  position: relative;
}

.map-page .map-controls {
  height: calc(100% - 72px);
  height: calc(100% - var(--map-controls-bottom-offset));
  @media (min-width: 960px) {
    height: 100%;
  }
}

$mobilePaddingTop: 48px;

.info-panel-header-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.info-panel-container {
  //direction: ltr;
  overflow-x: hidden;
  .info-panel__panel-toggle {
    display: none;
  }

  @media (max-width: 959px) {
    position: absolute;
    width: 100%;
    z-index: 3;
    height: 100%;
    //padding-right: $mobilePaddingRight;
    padding-top: $mobilePaddingTop;
    .info-panel__panel-toggle {
      display: none;
      //display: inline-flex;
      position: absolute;
      box-shadow: none;
      padding: 12px;
      min-width: 0 !important;
      //left: calc(100% - #{$mobilePaddingRight});
      top: calc(50% - 24px);
    }
  }
}

.map-dim {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.info-panel {
  float: right;

  max-width: 420px;
  width: 100vw;

  border-left: 1px solid rgba(0, 0, 0, 0.07);
  border-color: rgba(0, 0, 0, 0.07) !important;

  @media (max-width: 959px) {
    //width: calc(100vw - #{$mobilePaddingRight});
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-left: none;
    position: relative;
    max-width: 100%;
    margin: auto;
    &:after {
      content: " ";
      height: 4px;
      border-radius: 2px;
      width: 24px;
      background: currentColor;
      opacity: 0.335;
      position: absolute;
      top: 6px;
      transform: translate(-50%);
      left: 50%;
    }
  }
  z-index: 1;
  background: white;

  height: 100%;

  display: flex;
  flex-direction: column;

  .category-card {
    flex-basis: 120px;
    flex-grow: 1;
    margin: 6px;
    font-size: 0.875rem;
    line-height: normal;
  }
  &__category-grid {
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: 1fr 1fr;
    // justify-items: stretch;
  }
  &__nav {
    border-bottom: thin solid #ddd;
  }
  &__content {
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
  }
}

.v-list-item {
}

.map-page .mapboxgl-ctrl-attrib,
.map-page .mapboxgl-ctrl-geolocate {
  display: none;
}

.app-map-legend__item {
  position: relative;
  display: block;
  width: 100%;
  padding-left: 24px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(var(--color-secondary-100));
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
</style>
