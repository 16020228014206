<template>
  <v-card style="height: 400px;">
    <div class="d-flex align-center">
      <v-card-title>Сохранить в...</v-card-title>
      <v-spacer />
      <v-btn
        class="mr-2"
        @click="
          showAddNew = false;
          $emit('close');
        "
        icon
        large
        ><v-icon>$close</v-icon></v-btn
      >
    </div>
    <v-divider></v-divider>
    <v-progress-linear indeterminate v-if="loading" />
    <v-card-text>
      <v-checkbox
        v-for="list in lists"
        :key="list.id"
        hide-details
        :label="list.name"
        :indeterminate="list.someIncluded && !list.allIncluded"
        v-model="list.allIncluded"
        @change="selectHandler(list, $event)"
      />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-0">
      <v-btn
        v-if="!showAddNew"
        @click="showAddNew = true"
        text
        block
        large
        class="text-center"
      >
        <v-icon left>mdi-plus</v-icon> Создать новый список
      </v-btn>
      <div class="pa-3" style="width:100%" v-else>
        <div class="px-4">
          <v-text-field
            maxlength="100"
            counter="100"
            v-model="name"
            outlined
            dense
            style="width:100%"
            label="Название"
            :rules="titleRules"
            ref="titleField"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-spacer />
          <v-btn text color="primary" @click="createListHandler">Создать</v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import opencity from "@/service/opencity";

export default {
  data() {
    return {
      showAddNew: false,
      name: "",
      lists: [],
      titleRules: [val => val?.length > 2 || "Минимум 3 символа"],
      loading: false
    };
  },
  props: {
    items: {
      required: true,
      type: Array
    },
    type: {
      required: true,
      type: String
    }
  },
  async created() {
    await this.updateLists();
  },
  watch: {
    items() {
      this.lists = [];
      this.updateLists();
    }
  },
  methods: {
    async updateLists() {
      this.loading = true;
      const objectId = this.items?.map(el => el.id).join(",");
      this.lists = await opencity
        .get("/lists" + (objectId ? `?objectId=` + objectId : ""))
        .then(res => res.json());
      this.loading = false;
    },
    createListHandler() {
      if (!this.$refs.titleField.validate(true)) return;
      this.createList(this.name);
      this.name = "";
      this.showAddNew = false;
    },
    async createList(name) {
      await opencity.post("/lists", {
        name: name,
        entries: this.items.map(el => el.id)
      });
      await this.updateLists();
    },
    selectHandler(list, ev) {
      if (ev === false) {
        list.someIncluded = false;
      }
      this.$emit("save", this.lists);
      opencity.post("/lists/" + list.id + (ev ? "" : "/delete"), {entries: this.items.map(el=>el.id)}).then(() => {
        this.$emit("change", list);
      });
    },
  }
};
</script>

<style></style>
