<template>
  <div v-if="preferences" class="pa-3 pa-md-4 ma-auto" style="max-width: 720px">
    <span class="text-h4 font-weight-medium mb-3 px-4 d-block">Настройки</span>
    <v-list class="rounded-lg">
      <label>
        <v-list-item link>
          <v-list-item-title>Анимации</v-list-item-title>
          <v-list-item-action>
            <v-switch
              @click.prevent
              v-model="preferences.animations"
              @change="save"
            />
          </v-list-item-action>
        </v-list-item>
      </label>
      <label>
        <v-list-item link>
          <v-list-item-title>Тема</v-list-item-title>
          <v-list-item-action>
            <v-select
              dense
              item-text="title"
              item-value="key"
              v-model="preferences.darkMode"
              :items="themeOptions"
              hide-details
              @change="save"
            />
          </v-list-item-action>
        </v-list-item>
      </label>
    </v-list>
    <v-list class="rounded-lg mt-4">
      <v-list-item v-if="!changePassword" @click="changePassword = true"
        >Сменить пароль</v-list-item
      >
      <v-form v-if="changePassword" ref="changePasswordForm" class="pa-3">
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          :append-icon="
            showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="showPassword = !showPassword"
          :rules="oldPasswordRules"
          v-model="oldPassword"
          outlined
          dense
          label="Текущий пароль"
        ></v-text-field>
        <v-text-field
          :rules="passwordRules"
          v-model="newPassword"
          type="password"
          outlined
          dense
          label="Новый пароль"
        ></v-text-field>
        <v-text-field
          :rules="repeatPasswordRules"
          type="password"
          outlined
          dense
          label="Повторите пароль"
        ></v-text-field>
        <div class="d-flex">
          <v-spacer />
          <v-btn @click="changePassword = false" depressed class="mx-2"
            >Отмена</v-btn
          >
          <v-btn @click="requestPasswordChange" depressed color="primary"
            >Изменить пароль</v-btn
          >
        </div>
      </v-form>
    </v-list>
    <v-dialog v-model="wrongPasswordDialog" persistent max-width="290">
      <v-card>
        <v-card-title style="word-break: normal"
          >Неверный текущий пароль</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="wrongPasswordDialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PanelFrame from "@/components/PanelFrame";
import opencity from "@/service/opencity";

const defaultSettings = {
  darkMode: "auto",
  animations: true
};

export default {
  name: "UserSettings",
  data: () => ({
    themeOptions: [
      { title: "Системная", key: "auto" },
      { title: "Тёмная", key: "dark" },
      { title: "Светлая", key: "light" }
    ],
    newPassword: "",
    oldPassword: "",
    preferences: null,
    changePassword: false,
    oldPasswordRules: [v => v?.length || "Обязательное поле"],
    passwordRules: [
      v =>
        (v?.length >= 8 &&
          /\d/.test(v) &&
          /[A-ZА-Я]/.test(v) &&
          /[a-zа-я]/.test(v)) ||
        "Должен содержать не менее 8 символов, как минимум одну заглавную, одну строчную букву и одну цифру"
    ],
    wrongPasswordDialog: false,
    showPassword: false
  }),
  computed: {
    repeatPasswordRules() {
      return [val => val === this.newPassword || "Пароли не совпадают"];
    }
  },
  created() {
    opencity.getPreferences("panel", true).then(p => {
      this.preferences = { ...defaultSettings, ...p };
    });
    this.$emit("update:layout", PanelFrame);
  },
  methods: {
    save() {
      opencity.setPreferences("panel", this.preferences);
    },
    requestPasswordChange() {
      if (!this.$refs["changePasswordForm"]?.validate()) {
        return;
      }

      if (this.newPassword?.length) {
        opencity
          .changePassword(this.oldPassword, this.newPassword)
          .then(() => {
            this.changePassword = false;
          })
          .catch(e => {
            this.wrongPasswordDialog = true;
          });
      }
    }
  }
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
