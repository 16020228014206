<template>
  <v-app-bar
    style="z-index:7;border-bottom: thin solid rgba(0, 0, 0, 0.12);"
    height="56"
    elevation="0"
    max-height="56"
    :color="testVersion ? 'warning' : null"
  >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    <v-toolbar-title
      class="d-md-block d-none"
      v-text="
        user.email.startsWith('admin')
          ? 'Администратор системы'
          : 'Сотрудник организации'
      "
    >
    </v-toolbar-title>

    <v-toolbar-title v-if="testVersion" class="text--disabled ml-2"> (тестовый)</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-badge
          :value="notifications > 0"
          :content="notifications > 99 ? '99+' : notifications"
          offset-x="22"
          offset-y="22"
          color="accent"
          class="notification-badge mr-0"
          bordered
        >
          <v-btn icon v-on="on">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <NotificationsPopup />
    </v-menu>

    <v-menu offset-y :close-on-content-click="true">
      <template v-slot:activator="{ on }">
        <v-badge
          bordered
          dot
          color="success lighten-1"
          offset-x="14"
          offset-y="14"
          bottom
        >
          <v-btn icon class="ml-" v-on="on">
            <v-avatar color="teal" size="40">
              <span class="white--text header">{{ user.firstName[0] }}</span>
            </v-avatar>
          </v-btn>
        </v-badge>
      </template>
      <v-card max-width="300" width="min-content">
        <v-list-item>
          <v-list-item-avatar color="teal">
            <span class="white--text header">{{ user.firstName[0] }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 font-weight-bold">{{
              user.fullName
            }}</v-list-item-title>
            <v-list-item-subtitle class="caption mr-4"
              >Статус <b>активный</b>.
<!--              <a href="#">Изменить</a>-->
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list dense>
          <v-list-item to="/settings">
            <v-list-item-title>Настройки</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title>Избранное</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title>Контент</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list dense>
          <v-list-item link @click="signOut">
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <!-- </v-btn> -->
  </v-app-bar>
</template>

<script>
import NotificationsPopup from "@/components/NotificationsPopup";

import opencity from "../../service/opencity";

export default {
  components: {
    NotificationsPopup
  },

  data() {
    return {
      notifications: 0,
      show_profile_menu: false,
      border_test: false,
      animations: false,
      user: opencity.userObject,
      testVersion: process.env.VUE_APP_VARIANT === "demo"
    };
  },

  mounted() {
    opencity.addAuthListener(auth => this.updateAuth(auth));
    if (!this.user) {
      opencity.getMe().then(() => {
        this.user = opencity.userObject;
      });
    }
  },

  unmounted() {},

  props: {
    drawerModel: {
      type: Boolean
    }
  },

  computed: {
    drawer: {
      set(val) {
        this.$emit("update:drawerModel", val);
      },
      get() {
        return this.drawerModel;
      }
    }
  },

  methods: {
    signOut() {
      opencity.logout();
      this.$router.push("/login");
    },
    updateAuth(auth) {
      if (!auth) {
        this.$router.push("/login");
      } else {
        opencity.getMe().then(() => {
          this.user = opencity.userObject;
        });
      }
    }
  }
};
</script>
