import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.item.isList)?_c(VListItem,{staticClass:"drawer-menu-item",attrs:{"link":"","color":"primary","to":_vm.item.to,"exact-path":""}},[_c(VListItemIcon,[_c(VIcon,{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.title))])],1),(_vm.item.count)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.item.count)+" ")]):_vm._e()],1):_c(VListGroup,{staticClass:"drawer-menu-item",attrs:{"prepend-icon":_vm.item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.title))])]},proxy:true}])},_vm._l((_vm.children),function(child){return _c('menu-item',{key:child.title,attrs:{"item":child}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }