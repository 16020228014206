import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,_vm._g(_vm._b({staticClass:"table-layout",attrs:{"item-key":_vm.rowKey,"show-select":"","calculate-widths":"","footer-props":{
    'items-per-page-options': [10, 20, 30]
  },"loading":_vm.loading},on:{"item-selected":_vm.itemSelectHandler,"contextmenu":function($event){$event.preventDefault();},"input":_vm.inputHandler},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
  var select = ref.select;
  var isSelected = ref.isSelected;
return [(_vm.pinned.length)?[_vm._l((_vm.pinned),function(item){return _c(_vm.rowComponent,{key:item.id,tag:"component",staticClass:"pinned-row",attrs:{"item":item,"select":select.bind(this, item),"is-selected":isSelected(item)},on:{"click":_vm.rowClickHandler,"dblclick":_vm.rowDbclickHandler}})}),_c('tr',{staticClass:"row-divider"},[_c('td',{staticStyle:{"height":"21px"},attrs:{"colspan":_vm.headers.length + 1}})])]:_vm._e()]}},{key:"item",fn:function(ref){
  var item = ref.item;
  var select = ref.select;
  var isSelected = ref.isSelected;
return [_c(_vm.rowComponent,{tag:"component",attrs:{"item":item,"select":select,"is-selected":isSelected},on:{"click":_vm.rowClickHandler,"dblclick":_vm.rowDbclickHandler}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }