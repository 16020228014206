<template>
  <div class="d-flex fill-height flex-column align-center justify-center">
    <div class="mt-n8 mb-6 d-flex display-1 font-weight-bold align-center">
      Зеленый город
    </div>
    <v-card
      :loading="sending"
      width="100%"
      max-width="392"
      class="ma-2 light-shadow"
    >
      <v-card-title class="justify-center py-10">
        Вход
      </v-card-title>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-card-text class="py-0">
          <v-label>
            <span class="font-weight-bold">
              Почта
            </span>
            <v-text-field
              class="mt-2"
              :rules="rules.email"
              v-model="email"
              id="email"
              dense
              outlined
            />
          </v-label>
          <v-label>
            <span class="font-weight-medium">
              Пароль
            </span>
            <v-text-field
              class="mt-2"
              :rules="rules.password"
              v-model="password"
              dense
              outlined
              id="pass"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="showPassword = !showPassword"
            />
          </v-label>
          <div class="d-flex">
            <v-checkbox v-model="rememberMe" hide-details label="Запомнить меня" class="py-0 my-0" />
            <v-spacer />
<!--            <a>Забыли пароль?</a>-->
          </div>
        </v-card-text>
        <v-card-actions class="align-baseline px-4 py-10">
          <v-btn
            @submit.prevent="login"
            @click.prevent="login"
            type="submit"
            block
            height="40"
            depressed
            color="primary"
          >
            Войти
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-dialog max-width="240" v-model="showWrong">
      <v-card>
        <v-card-title></v-card-title>

        <v-card-text>
          {{ error }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showWrong = false">
            Попробуйте снова
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmptyFrame from "@/components/EmptyFrame";
import opencity from "../service/opencity";

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      showWrong: false,
      error: "",
      rules: {
        email: [
          v => !!v || "Обязательное поле",
          v => /.+@.+\..+/.test(v) || "Недействительный адрес"
        ],
        password: [v => !!v || "Обязательное поле"]
      },
      valid: false,
      sending: false,
      autofill: false,
      intervalDetectAutofill: null,
      rememberMe: true
    };
  },
  methods: {
    showError(msg) {
      this.showWrong = true;
      this.error = msg;
    },
    async login() {
      const { email, password, sending, rememberMe } = this;

      if (sending) return;
      if (!this.$refs.form.validate()) return;

      this.sending = true;

      try {
        console.log(
          await opencity.login(email.toLowerCase(), password, rememberMe)
        );
        const me = await opencity.getMe();
        if (!me.panelAccess) {
          this.sending = false;
          this.showError("У вас нет доступа к панели управления");
          opencity.logout();
          return;
        }
        this.$router.push({ path: "/" });
        this.sending = false;
      } catch (e) {
        console.log(e);
        this.sending = false;
        this.showError("Неправильная почта или пароль");
      }
    }
  },
  created() {
    this.$emit("update:layout", EmptyFrame);
  }
};
</script>

<style lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
