<template>
  <v-app :class="appClass" :style="appStyle">
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
    <v-snackbar
      bottom
      right
      :value="updateExists || updateLoading"
      :timeout="-1"
      color="primary"
    >
      Доступно обновление
      <template v-slot:action="{ attrs }">
        <v-btn v-if="updateExists" text @click="refreshApp" v-bind="attrs">
          Обновить
        </v-btn>
        <v-progress-circular v-else width="3" indeterminate />
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from "@/mixins/pwa-update";
import panel from "@/service/panel";

export default {
  name: "App",

  data: () => ({
    appClass: {
      "disable-animations": false,
      "no-border-bottom-test": false
    },
    appStyle: {},
    layout: "div"
  }),
  created() {
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    let darkModeListener = () =>
      (this.$vuetify.theme.dark = darkModeQuery.matches);
    panel.usePanelPreferences(val => {
      this.appClass["disable-animations"] = !val.animations;
      if (val.darkMode === "auto") {
        darkModeListener();
        darkModeQuery.onchange = darkModeListener;
      } else {
        darkModeQuery.onchange = null;
        this.$vuetify.theme.dark = val.darkMode === "dark";
      }
    });
  },
  computed: {
    primaryRGB() {
      let hex = this.$vuetify.theme.currentTheme.primary;
      let R = parseInt(hex[1] + hex[2], 16);
      let G = parseInt(hex[3] + hex[4], 16);
      let B = parseInt(hex[5] + hex[6], 16);

      return R + ", " + G + ", " + B;
    }
  },
  mixins: [update],
  provide() {
    return {
      appStyle: this.appStyle
    };
  },
  watch: {
    primaryRGB: {
      immediate: true,
      handler(val) {
        this.$set(this.appStyle, "--primary-rgb", val);
      }
    }
  }
};
</script>

<style lang="scss">
.mapboxgl-ctrl-geolocate {
  display: none !important;
}

@media (pointer: coarse), (hover: none) {
  [title] {
    position: relative;
  }
  [title]:focus::after, [title]:focus-within::after {
    content: attr(title);
    position: absolute;
    text-align: center;
    top: 0;
    border-radius: 8px;
    left: 0;
    color: #000000;
    background-color: #ffffffee;
    border: 1px solid #00000055;
    width: max-content;
    padding: 3px;
    z-index: 1;
  }
}

.pswp__img--placeholder {
  display: none;
}

body .theme--light.v-icon {
  color: #67676a;
}

:root {
  overflow-y: auto;
  height: 100%;
}

.sticky {
  position: sticky !important;
  top: 0; //default
  z-index: 1;
}

.disable-animations {
  * {
    transition: none !important;
  }
}

.notification-badge .v-badge__badge {
  font-size: 10px !important;
  font-weight: 400 !important;
  min-width: 18px !important;
  height: 18px;
}

body {
  // overflow-x: hidden;
}


body .v-application {
  line-height: normal;
}
.hidden {
  visibility: hidden;
}

.light-shadow {
  box-shadow: 0px 1px 4px rgba(5, 5, 5, 0.15) !important;
}

.text-heading {
  font-size: 2rem;
  font-weight: 500;
}

.w-100 {
  width: 100%;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #f5f5f5;
}

body {
  font-family: "Roboto", sans-serif;
}

.d-none {
  display: none;
}

.v-dialog {
  border-radius: 12px !important;
}

.v-btn--text.v-btn--hover {
  opacity: 0.7;
}
.v-btn--icon.v-btn--hover {
  opacity: 0.8;
}
.v-btn--icon.v-btn--hover:hover,
.v-btn--text.v-btn--hover:hover {
  opacity: 1;
}
</style>
