import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-3"},[_vm._t("default",function(){return [_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Найти...","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_vm._v(" Найти слой... ")])]},{"search":_vm.search,"updateSearch":function (val) { return (_vm.search = val); }})],2),_c(VTreeview,{attrs:{"activatable":"","active":_vm.active,"search":_vm.search,"selected-color":"success","dense":"","open-on-click":"","item-text":"title","selectable":"","items":_vm.items1},on:{"update:active":_vm.updateActive},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c(VIcon,{staticClass:"options-icon",attrs:{"size":"22"},on:{"click":function($event){return _vm.$emit('filters', item.id)}}},[_vm._v("mdi-tune-variant")]):_vm._e(),(_vm.statsInternal && _vm.statsInternal[item.id])?_c('span',{staticClass:"type-count"},[_vm._v(_vm._s(_vm.statsInternal[item.id]))]):_vm._e()]}}]),model:{value:(_vm.tree1),callback:function ($$v) {_vm.tree1=$$v},expression:"tree1"}}),_c(VDivider,{staticClass:"my-3"}),_c('div',{staticClass:"px-3 my-2 font-weight-bold"},[_vm._v("Территории")]),_c(VTreeview,{attrs:{"activatable":"","active":_vm.active,"search":_vm.search,"selected-color":"success","dense":"","open-on-click":"","item-text":"title","selectable":"","items":_vm.items2},on:{"update:active":_vm.updateActive},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c(VIcon,{staticClass:"options-icon",attrs:{"size":"22"},on:{"click":function($event){return _vm.$emit('filters', item.id)}}},[_vm._v("mdi-tune-variant")]):_vm._e(),(_vm.statsInternal && _vm.statsInternal[item.id])?_c('span',{staticClass:"type-count"},[_vm._v(_vm._s(_vm.statsInternal[item.id]))]):_vm._e()]}}]),model:{value:(_vm.tree2),callback:function ($$v) {_vm.tree2=$$v},expression:"tree2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }