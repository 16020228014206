<template>
  <tr
    :class="{ 'v-data-table__selected': isSelected }"
    @click="$emit('click', item)"
  >
    <td class="text-start pr-0" @click.stop>
      <div style="height: 42px;" class="d-flex align-center">
        <v-checkbox
          hide-details
          class="ma-0 pa-0 v-data-table__checkbox"
          :input-value="isSelected"
          @change="to => select(to)"
        />
<!--        <v-btn color="primary darken-3" icon @click.stop="saveTo(item)">-->
<!--          <v-icon-->
<!--            :color="-->
<!--              item.saved ? 'amber darken-1' : isSelected ? 'primary' : 'grey'-->
<!--            "-->
<!--            :style="`opacity: ${!item.saved && isSelected ? 0.7 : 1}`"-->
<!--            size="24"-->
<!--            >{{ !item.saved ? "mdi-bookmark-outline" : "mdi-bookmark" }}</v-icon-->
<!--          >-->
<!--        </v-btn>-->
      </div>
    </td>
    <td class="text-start pr-0" style="width:5em">{{ item.id }}</td>
    <td class="text-start">
      <div class="d-flex">
        <span
          style="min-width:0;text-overflow:ellipsis;overflow:hidden;white-space:nowrap"
          >{{ name }}</span
        >

        <v-tooltip v-if="item.marked" bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="accent" class="ml-1" v-on="on"
              >mdi-lightning-bolt</v-icon
            >
          </template>
          <span>Marker</span>
        </v-tooltip>
      </div>
    </td>
    <td class="text-start">{{ item.location }}</td>
    <td class="text-start">{{ type }}</td>
    <td class="text-start">{{ item.author ? item.author.fullName : 'Пусто' }}</td>
    <td class="text-start">{{ item.author ? item.author.email : 'Пусто' }}</td>
<!--    <Cell align="end" :filter="datetime">{{ item.createdAt }}</Cell>-->
    <Cell align="end" :filter="datetime">{{ item.updatedAt }}</Cell>
    <!-- <Cell
      v-for="cellInfo in structure"
      :key="cellInfo.title"
      :align="cellInfo.align"
      :filter="cellInfo.filter"
      :style="cellInfo.style"
    >
      {{ item[cellInfo.field] }}
    </Cell> -->
  </tr>
</template>

<script>
import { datetime2 } from "@/utils/datetime";

let cells = [
  {
    title: "ID",
    field: "id",
    style: "width:5em",
    align: "start"
  },
  {
    title: "Title",
    field: "name",
    align: "start",
    flex: ""
  },
  {
    title: "Email",
    align: "start",
    field: "email"
  },
  {
    title: "Region",
    align: "start",
    field: "region"
  },
  {
    title: "Status",
    align: "start",
    field: "status"
  },
  {
    title: "Registered",
    align: "end",
    filter: datetime2,
    field: "registered"
  },
  {
    title: "Updated",
    align: "end",
    filter: datetime2,
    field: "updated"
  }
];

let Cell = {
  functional: true,
  props: ["align", "filter", "custom"],
  render(h, { props, data, children }) {
    if (props.custom) return h(props.custom, data, children);

    if (!data.class) data.class = {};
    switch (props.align) {
      case "start":
        data.class["text-start"] = true;
        break;
      case "end":
        data.class["text-end"] = true;
        break;
      default:
    }

    if (props.filter) {
      children = props.filter(children[0]?.text);
    }

    return h(`td`, data, children);
  }
};

export default {
  components: {
    Cell
  },
  data() {
    return {
      datetime: datetime2
    };
  },
  computed: {
    name() {
      return this.item.title;
    },
    type() {
      return this.item.objectType?.title;
    }
  },
  methods: {
    saveTo(saveWhat) {
      this.$emit("save", saveWhat);
    }
  },
  props: {
    item: {},
    isSelected: {},
    select: {},
    structure: {
      default() {
        return cells;
      }
    }
  }
};
</script>

<style>
.table-name-row {
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 30%;
}
</style>
