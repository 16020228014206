import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

Vue.use(Vue => {
  Vue.mixin({
    beforeCreate() {
      this.$debugger = function() {
        debugger;
      };
      this.$blur = function() {
        document.activeElement.blur();
      };
      this.$log = function(val) {
        console.log("Using $log: ", val);
      };
    }
  });
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
