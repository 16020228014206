import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticStyle:{"z-index":"7","border-bottom":"thin solid rgba(0, 0, 0, 0.12)"},attrs:{"height":"56","elevation":"0","max-height":"56","color":_vm.testVersion ? 'warning' : null}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,{staticClass:"d-md-block d-none",domProps:{"textContent":_vm._s(
        _vm.user.email.startsWith('admin')
          ? 'Администратор системы'
          : 'Сотрудник организации'
      )}}),(_vm.testVersion)?_c(VToolbarTitle,{staticClass:"text--disabled ml-2"},[_vm._v(" (тестовый)")]):_vm._e(),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c(VBadge,{staticClass:"notification-badge mr-0",attrs:{"value":_vm.notifications > 0,"content":_vm.notifications > 99 ? '99+' : _vm.notifications,"offset-x":"22","offset-y":"22","color":"accent","bordered":""}},[_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-bell-outline")])],1)],1)]}}])},[_c('NotificationsPopup')],1),_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c(VBadge,{attrs:{"bordered":"","dot":"","color":"success lighten-1","offset-x":"14","offset-y":"14","bottom":""}},[_c(VBtn,_vm._g({staticClass:"ml-",attrs:{"icon":""}},on),[_c(VAvatar,{attrs:{"color":"teal","size":"40"}},[_c('span',{staticClass:"white--text header"},[_vm._v(_vm._s(_vm.user.firstName[0]))])])],1)],1)]}}])},[_c(VCard,{attrs:{"max-width":"300","width":"min-content"}},[_c(VListItem,[_c(VListItemAvatar,{attrs:{"color":"teal"}},[_c('span',{staticClass:"white--text header"},[_vm._v(_vm._s(_vm.user.firstName[0]))])]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.user.fullName))]),_c(VListItemSubtitle,{staticClass:"caption mr-4"},[_vm._v("Статус "),_c('b',[_vm._v("активный")]),_vm._v(". ")])],1)],1),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"to":"/settings"}},[_c(VListItemTitle,[_vm._v("Настройки")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Избранное")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,[_vm._v("Контент")])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"link":""},on:{"click":_vm.signOut}},[_c(VListItemTitle,[_vm._v("Выйти")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }