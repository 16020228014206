import mapStyles from "../utils/mapStyles";
import bbox from "@turf/bbox";
import simplify from "@turf/simplify";
import genera from "@/assets/species";
import generaBush from "@/assets/speciesBush";
import damage from "@/assets/damage";
import categories from "@/assets/categories";

const staticTypes = categories.flatMap(c => c.topics);

class OpenCityService {
  API_BASE = null;

  authenticated = false;
  username = null;
  userObject = null;
  refreshing = null;
  remember = false;
  access_token = null;
  access_token_expiration = null;
  refresh_token = null;
  refresh_refresh_token_expiration = null;

  settings = {
    // mapStyle: "schematic"
  };

  AUTHORIZATION_PREFIX = "Bearer ";

  authEventListeners = new Set();

  constructor(apiBase = null) {
    if (apiBase) this.API_BASE = apiBase;
    if (!this.API_BASE) {
      throw new ReferenceError("No API url provided");
    }
    this.restoreSettings();
  }

  _listeners = {};

  on(event, callback) {
    if (!this._listeners[event]) {
      this._listeners[event] = [];
    }
    this._listeners[event].push(callback);
  }

  off(event, callback) {
    this._listeners[event]?.filter(el => el !== callback);
  }

  _dispatch(event, payload) {
    this._listeners[event]?.forEach(listener => listener(payload));
  }

  addAuthListener(cb) {
    this.on("update:auth", cb);
  }

  _setAuthenticated(auth) {
    this.authenticated = auth;
    this._dispatch("update:auth", auth);
  }

  async get(path, auth = true, options = {}) {
    if (auth) {
      if (this.refreshing) await this.refreshing;
      if (!this.access_token) throw new Error("NO_AUTH");
    }
    const response = await fetch((this.API_BASE || "") + path, {
      method: "GET",
      headers: {
        Authorization:
          this.access_token && this.AUTHORIZATION_PREFIX + this.access_token
      },
      ...options
    });
    if (
      response.status === 401 &&
      (await response.json()).error === "invalid_token"
    ) {
      this._refresh();
      return await this.get(path, auth, options);
    }
    return response;
  }

  async post(path, data, auth = true) {
    if (auth) {
      if (this.refreshing) await this.refreshing;
      if (!this.access_token) throw new Error("NO_AUTH");
    }
    const response = await fetch((this.API_BASE || "") + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth
          ? this.access_token && this.AUTHORIZATION_PREFIX + this.access_token
          : undefined
      },
      body: JSON.stringify(data)
    });
    if (
      response.status === 401 &&
      (await response.json()).error === "invalid_token"
    ) {
      this._refresh();
      return await this.post(path, data, auth);
    }
    return response;
  }

  async delete(path, data, auth = true) {
    if (auth) {
      if (this.refreshing) await this.refreshing;
      if (!this.access_token) throw new Error("NO_AUTH");
    }
    const response = await fetch((this.API_BASE || "") + path, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth
          ? this.access_token && this.AUTHORIZATION_PREFIX + this.access_token
          : undefined
      },
      body: JSON.stringify(data)
    });
    if (
      response.status === 401 &&
      (await response.json()).error === "invalid_token"
    ) {
      this._refresh();
      return await this.post(path, data, auth);
    }
    return response;
  }


  getTaxonomy(key) {
    if (key === "trees") return genera;
    else if (key === "bushes") return generaBush;
    else if (key === "damage") return damage;
    else return null;
  }

  async login(login, password, rememberMe) {
    const loginResponse = await this.post(
      "/login",
      {
        username: login,
        password,
        extended: rememberMe
      },
      false
    ).then(res => res.json());
    if (loginResponse.error) {
      throw new Error("Incorrect login or password");
    }
    if (loginResponse.data) {
      await this._updateAuthCredentials(loginResponse.data, rememberMe);
    }
    return loginResponse;
  }

  async changePassword(oldPassword, newPassword) {
    const response = await this.post("/auth/changePassword", {
      username: this.username,
      oldPassword,
      newPassword
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      throw new Error("Invalid");
    }
  }

  async _updateAuthCredentials(data, remember) {
    const store = remember ? localStorage : sessionStorage;
    this.access_token = data.access_token;
    this.refresh_token = data.refresh_token;
    this.username = data.username;
    this.userObject = (
      await this.get("/people/me").then(res => res.json())
    ).data;
    store.setItem("access_token", this.access_token);
    store.setItem("refresh_token", this.refresh_token);
    store.setItem("username", this.username);
    store.setItem("user", JSON.stringify(this.userObject));
    this._setAuthenticated(true);
  }

  async _refresh(init = true) {
    if (init) {
      if (this.refreshing) return false;
      this.refreshing = this._refresh(false);
      return this.refreshing;
    }

    const response = await this.post(
      "/refresh",
      {
        refreshToken: this.refresh_token
      },
      false
    );
    const refreshResponse = await response.json();
    this.refreshing = null;
    if (refreshResponse?.data) {
      await this._updateAuthCredentials(
        refreshResponse.data,
        this.authIsRemembered()
      );
    } else {
      await this.logout();
    }
    return refreshResponse;
  }

  async logout() {
    this.access_token = null;
    this.refresh_token = null;
    this.username = null;
    this.userObject = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    localStorage.removeItem("user");
    this._setAuthenticated(false);
  }

  async getMe() {
    const res = await this.get("/people/me");

    if (res.status === 401) {
      this.logout();
      return {};
    }

    const me = await res.json();
    this.userObject = me.data;
    return me.data;
  }

  isAdmin() {
    this.username === "admin@opencity.io";
  }

  _updateToken() {
    // await this.getMe();
  }

  async uploadFile(file, onProgressCallback) {
    file.error = false;
    file.progress = 0;
    file.uploading = true;

    this._refresh();

    const xhr = new XMLHttpRequest();
    var formData = new FormData();
    formData.append("file", file.file);
    file.xhr = xhr;

    const success = await new Promise(resolve => {
      xhr.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          file.progress = event.loaded / event.total;
          // console.log("upload progress:", file.progress);
        }
      });
      xhr.addEventListener("loadend", () => {
        if (xhr.status === 200) {
          file.realId = JSON.parse(xhr.responseText).fileName;
          resolve(true)
        } else {
          resolve(false);
        }
      });
      xhr.addEventListener("error", () => {
        resolve(false);
      });
      xhr.open("POST", this.API_BASE + "/files/", true);
      xhr.setRequestHeader(
          "Authorization",
          this.AUTHORIZATION_PREFIX + this.access_token
      );
      xhr.setRequestHeader("Response-Type", "application/json");
      xhr.send(formData);
    });

    file.uploading = false;
    if (!success) {
      file.error = xhr.status || true;
    }
  }

  updatedClassifier = null;
  restoredClassifier = Object.freeze(
    JSON.parse(localStorage.getItem("classifier")) || []
  );
  getObjectType(id) {
    for (let c of this.getClassifier()) {
      for (let type of c.objectTypes) {
        if (type.id === id) return type;
      }
    }
  }

  getParentCategory(typeId) {
    return this.getClassifier().find(el => el.objectTypes.some(el => el.id === typeId))
  }

  async _updateClassifier() {
    if (this.classifierLoading) return;
    this.classifierLoading = true;

    const response = await this.get("/config/classifier");
    if (!response.ok) {
      this.classifierLoading = false;
      return;
    }
    const data = await response.json();

    if (!Array.isArray(data)) this.classifierLoading = false;

    data.sort((a, b) => b.rank - a.rank);
    data.forEach(category =>
      category.objectTypes.sort((a, b) => b.rank - a.rank)
    );
    data.forEach(category =>
      category.objectTypes.forEach(type => {
        type.schema = staticTypes.find(el => el.id == type.id)?.schema;
        if (!type.schema) {
          type.schema = {
            fields: [
              {
                name: "Площадь (м2)",
                type: "number",
                key: "area"
              },
              {
                name: "Назначение",
                type: "string",
                key: "purpose"
              }
            ]
          };
        }
      })
    );
    console.log("classifier updated", data);

    Object.freeze(data);
    localStorage.setItem("classifier", JSON.stringify(data));
    this.updatedClassifier = data;
    this._dispatch("update:classifier", data);
  }

  getClassifier() {
    if (!this.updatedClassifier) {
      this._updateClassifier();
      return this.restoredClassifier;
    }
    return this.updatedClassifier;
  }

  getDefaultRegion() {
    return {
      name: "Пермь",
      id: null,
      children: [],
      type: "region"
    };
  }

  regions = null;

  async getRegions() {
    if (!this.regions) {
      const regions = await this.get("/regions").then(res => res.json());
      // remap tree
      for (let rg of regions) {
        rg.children = [];
        for (let child of regions) {
          if (child.parentRegion?.id === rg.id) {
            rg.children.push(child);
            child.parentRegion = rg;
          }
        }
      }
      this.regions = regions;
    }
    return this.regions;
  }

  authIsRemembered() {
    return !!localStorage.getItem("access_token");
  }

  restoreLogin() {
    const store = this.authIsRemembered()
      ? localStorage
      : sessionStorage;
    const access_token = store.getItem("access_token");
    const refresh_token = store.getItem("refresh_token");
    const username = store.getItem("username");
    const user = JSON.parse(store.getItem("user"));
    if (refresh_token != null) {
      this.access_token = access_token;
      this.refresh_token = refresh_token;
      this.username = username;
      this.userObject = user;
      this._setAuthenticated(true);
      this.getMe();
      return true;
    }
    return false;
  }

  async postObject(object) {
    return this.post("/objects", object).then(res => res.text());
  }

  async deleteObject(id) {
    return await fetch((this.API_BASE || "") + `/objects/${id}`, {
      method: "DELETE",
      headers: {
        Authorization:
          this.access_token && this.AUTHORIZATION_PREFIX + this.access_token
      }
    });
  }

  async findPeople(query) {
    return this.get("/people/search?query=" + encodeURI(query));
  }

  async listObjects(
    pageSize = 20,
    pageId = 0,
    sort = "updatedAt,desc",
    query = "",
    types = null,
    regions = null,
    dataFilters = {},
    parentObjectId = null,
    dates = [null, null, null, null],
    author = null,
    visibility = null,
    list = null,
    moderated = true
  ) {
    let queryString = `page=${pageId}&size=${pageSize}`;
    if (sort) queryString += `&sort=` + sort + ",ignorecase";
    if (query) queryString += `&q=` + query;
    if (types) queryString += `&types=` + types.join(",");
    if (regions) queryString += `&regions=` + regions.join(",");
    if (parentObjectId) queryString += `&parent=` + parentObjectId;
    if (dataFilters)
      Object.entries(dataFilters).forEach(([k, v]) => {
        k = k.replace(",", "~");
        if (typeof v === "object") {
          if (v?.from) queryString += `&data[${k}]=gt~${v.from}`;
          if (v?.to) queryString += `&data[${k}]=lt~${v.to}`;
          if (v?.has) queryString += `&data[${k}]=has~${v.has}`
          return;
        }
        return v && (queryString += `&data[${k}]=` + v);
      });
    if (dates[0]) queryString += `&createdFrom=` + dates[0];
    if (dates[1]) queryString += `&createdTo=` + dates[1];
    if (dates[2]) queryString += `&updatedFrom=` + dates[2];
    if (dates[3]) queryString += `&updatedTo=` + dates[3];
    if (author) queryString += "&author=" + author;
    if (visibility !== null) queryString += "&visible=" + visibility;
    if (list !== null) queryString += "&list=" + list;
    if (moderated !== null) queryString += "&moderated=" + moderated;

    return this.get("/objects?" + queryString).then(res => res.json());
  }

  async getObject(id, options = {}) {
    return this.get("/objects/" + String(id), false, options).then(res =>
      res.json()
    );
  }

  getStaticMapFeatureURL(object, width = 800, height = 400) {
    if (!object?.geometry) return null;
    const { geometry } = object;
    if (geometry.type === "Point") {
      const [lat, lng] = geometry.coordinates;
      return `https://api.mapbox.com/styles/v1/oplayer/cl5wcnai6002614p26iolbdxr/static/pin-l-park+7cb342(${lat},${lng})/${lat},${lng},17/600x300?access_token=${process.env.VUE_APP_MAPBOX_KEY}&logo=false`;
    }
    const bounds = bbox(geometry);
    const scale = Math.max(bounds[2] - bounds[0], bounds[3] - bounds[1]);
    try {
      const polygon = simplify(geometry, { tolerance: scale / 100 });

      let encoded = encodeURIComponent(
        JSON.stringify({
          type: "Feature",
          geometry: polygon,
          properties: { fill: "#7cb342", stroke: "#7cb342", "stroke-width": 5 }
        })
      );

      return `https://api.mapbox.com/styles/v1/oplayer/cl5wcnai6002614p26iolbdxr/static/geojson(${encoded})/auto/${width}x${height}?access_token=${
        process.env.VUE_APP_MAPBOX_KEY
      }&logo=false`;
    } catch (e) {
      return null;
    }
  }

  getMediaUrl(media) {
    if (media.src) return media.src;
    return (process.env.VUE_APP_IMAGE_BASE ?? `/uploads/`) + media.store_path;
  }

  getMediaPreviewUrl(media) {
    if (media.previewSrc) return media.previewSrc;
    if (media.src) return media.src;
    return (process.env.VUE_APP_IMAGE_BASE ?? `/uploads/`) + `preview/` + media.store_path;
  }

  async probeIntersectingObjects(geometry) {
    return this.post(`/objects/probe`, geometry).then(res => res.json());
  }

  async probeObjects(lat, lng) {
    return this.get(`/objects/probe/${lat}/${lng}`).then(res => res.json());
  }

  async probeRegions(lat, lng) {
    return this.get(`/regions/probe/${lat}/${lng}`).then(res => res.json());
  }

  async getPerson(id) {
    return this.get("/people/" + String(id))
      .then(res => res.json())
      .then(res => res.data);
  }

  getTilesUrl(type_id, filters = {}) {
    // filters.dataFilters = filters.data
    type_id = null;
    let queryString = this.queryStringFromOptions(filters);

    if (type_id) {
      return (
        this.API_BASE + `/objects/layer/${type_id}/{z}/{x}/{y}?` + queryString
      );
    } else {
      return this.API_BASE + "/objects/layer/{z}/{x}/{y}?" + queryString;
    }
  }

  getFeatureStyleFor(typeId, variant = 0) {
    return this.getClassifier();
  }

  queryObjectFromOptions(options) {
    const {
      pageSize,
      pageId,
      sort,
      query,
      types,
      regions,
      dataFilters,
      parent,
      createdFrom,
      createdTo,
      updatedFrom,
      updatedTo,
      visible,
      author,
      list,
      moderated
    } = options;

    let es = {
      page: pageId,
      size: pageSize,
      sort,
      q: query,
      types,
      regions,
      parent,
      createdFrom,
      createdTo,
      updatedFrom,
      updatedTo,
      visible,
      author,
      list,
      moderated
    };
    if (dataFilters)
      Object.entries(dataFilters).forEach(([k, v]) => {
        k = k.replace(',', '~');
        if (!v) return;
        es[`data[${k}]`] = [];
        if (typeof v === "object") {
          if (v?.from) es[`data[${k}]`].push(`gt~${v.from}`);
          if (v?.to) es[`data[${k}]`].push(`lt~${v.to}`);
          if (v?.has) es[`data[${k}]`].push(`has~${v.has}`);
          return;
        }
        return (es[`data[${k}]`] = v);
      });
    return es;
  }

  queryStringFromOptions(options) {
    const obj = this.queryObjectFromOptions(options);
    return Object.entries(obj)
      .filter(e => e[1] !== null && e[1] !== undefined)
      .map(e => Array.isArray(e[1]) ? e[1].map(el => e[0] + "=" + el).join("&") : (e[0] + "=" + e[1])  )
      .join("&");
  }

  getVectorSource(type_id) {
    return {
      type: "vector",
      tiles: [this.getTilesUrl(type_id)]
    };
  }

  getDefaultMapStyle() {
    return mapStyles[this.settings.mapStyle || "schematic"];
  }

  saveMapStylePreference(key) {
    this.settings.mapStyle = key;
    this.saveSettings();
  }

  getShowSearchPreference() {
      return this.settings.showSearch ?? false;
  }

  saveShowSearchPreference(show) {
    this.settings.showSearch = show;
    this.saveSettings();
  }

  saveSettings() {
    localStorage.setItem("settings", JSON.stringify(this.settings));
  }

  restoreSettings() {
    const str = localStorage.getItem("settings");
    if (str) {
      this.settings = JSON.parse(str);
    }
  }

  preferences = {};

  getPreferences(key, fetch = false) {
    if (this.authenticated) {
      const update = this.get("/preferences/" + key)
        .then(el => (el.status === 200 ? el.json() : null))
        .then(response => {
          const data = response?.data;
          if (!data) return;
          const updatedAt = new Date(response.updatedAt).getTime();
          if (updatedAt <= this.preferences[key]?.updatedAt)
            return response.data;
          this.preferences[key] = { data, updatedAt };
          localStorage.setItem(
            "preferences_user",
            JSON.stringify(this.preferences)
          );
          this._dispatch(`update:preferences[${key}]`, response.data);
          return response.data;
        });
      if (fetch) return update;
    }

    return this.preferences[key]?.value;
  }

  setPreferences(key, data) {
    this.preferences[key] = {
      data,
      updatedAt: Date.now()
    };
    if (this.authenticated)
      this.post("/preferences/" + key, data)
        .then(res => res.json())
        .then(response => {
          const data = response?.data;
          const updatedAt = new Date(response.updatedAt).getTime();
          this.preferences[key] = { data, updatedAt };
          localStorage.setItem(
            "preferences_user",
            JSON.stringify(this.preferences)
          );
        });
    else {
      localStorage.setItem(
        "preferences_anon",
        JSON.stringify(this.preferences)
      );
    }
    localStorage.setItem("preferences_user", JSON.stringify(this.preferences));
    this._dispatch(`update:preferences[${key}]`, data);
  }

  getMapStyles() {
    return {
      schematic: "Схема",
      imagery: "Спутник",
      osm: "OpenStreetMap",
      empty: "Без подложки"
    };
  }
}

const s = new OpenCityService(process.env.VUE_APP_API_BASE);
export default s;

export function toText(type, value) {
  if (!value) return "–";
  if (type === "multiple_option") return value.map(el => el.name).join("\n");
  if (type === "genera") return value.name;
  return value;
}