<template>
  <div>
    <Drawer v-model="drawer">
      <template v-slot:header>
        <div style="height:56px;">
          <div style="height:55px;" class="d-flex align-center pl-1 pr-4">
            <v-btn @click="drawer = !drawer" width="48" height="48" icon>
              <v-icon size="24">mdi-menu</v-icon>
            </v-btn>
            <v-spacer />
          </div>
        </div>
      </template>
    </Drawer>

    <AppBar :drawer-model.sync="drawer" />

    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
import Drawer from "@/components/NavDrawer";
import AppBar from "./PanelAppBar";

export default {
  name: "PanelFrame",

  components: {
    Drawer,
    AppBar
  },

  data: () => ({
    drawer: false,
    animations: true,
    border_test: true,
    auth: false
  }),

  methods: {
    signOut() {
      this.auth = false;
    },
    onAuth() {
      this.auth = true;
    }
  },

  updated() {
    // console.log("UNEE");
  }
};
</script>
