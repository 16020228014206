import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VToolbar,{staticClass:"action-bar pa-0",attrs:{"flat":"","dense":""}},[_c(VContainer,{staticClass:"pa-0 d-flex align-center",staticStyle:{"max-width":"1280px"}},[_vm._t("default",null,null,{ onlyOneSelected: _vm.onlyOneSelected, someSelected: _vm.someSelected, noneSelected: _vm.noneSelected }),(!_vm.custom)?[_c(VExpandXTransition,[(_vm.someSelected)?[_c('div',{staticClass:"d-flex"},[_vm._t("someSelected"),_c(VExpandXTransition,[(_vm.onlyOneSelected)?_c('div',{staticClass:"d-flex"},[_vm._t("onlyOneSelected")],2):_vm._e()])],2)]:_vm._e()],2),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"v-btn--hover",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,3600864189)},[_c(VList,[_vm._t("menu",null,null,{ onlyOneSelected: _vm.onlyOneSelected, someSelected: _vm.someSelected, noneSelected: _vm.noneSelected }),(_vm.noneSelected)?_c(VListItem,{staticClass:"font-italic font-weight-light"},[_vm._v(" Выделите записи чтобы увидеть действия ")]):_vm._e()],2)],1),_c(VSpacer),_vm._t("append")]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }