import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{class:_vm.appClass,style:(_vm.appStyle)},[_c(_vm.layout,{tag:"component"},[_c('router-view',{attrs:{"layout":_vm.layout},on:{"update:layout":function($event){_vm.layout=$event}}})],1),_c(VSnackbar,{attrs:{"bottom":"","right":"","value":_vm.updateExists || _vm.updateLoading,"timeout":-1,"color":"primary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [(_vm.updateExists)?_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":_vm.refreshApp}},'v-btn',attrs,false),[_vm._v(" Обновить ")]):_c(VProgressCircular,{attrs:{"width":"3","indeterminate":""}})]}}])},[_vm._v(" Доступно обновление ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }