<template>
  <v-form autocomplete="off" class="w-100">
    <v-container fluid class="py-4 px-4">
      <div autocomplete="off" class="search-bar" :class="{ mobile: isMobile }">
        <v-text-field
          class="search-field custom-field-border-behavior"
          dense
          filled
          :background-color="background"
          outlined
          prepend-inner-icon="mdi-magnify"
          clearable
          hide-details
          placeholder="Найти..."
          v-model="query"
          height="40"
        />
        <ZoneSelector
          v-if="$vuetify.breakpoint.mdAndUp"
          class="search-select ml-4 custom-field-border-behavior rounded-r-0"
          dense
          hide-details
          height="40"
          outlined
          :filled="!$vuetify.theme.isDark"
          :background-color="background"
          v-model="selectedRegion"
        />
        <v-divider style="z-index:1" vertical />
        <div class="rounded-r" :style="{ background, height: '40px' }">
          <v-btn
            class="search-filters-btn custom-button-border-behavior"
            :class="{ active: showFIlters }"
            text
            @click="showFIlters = !showFIlters"
            :color="buttonColor"
            height="40"
          >
            {{ $vuetify.breakpoint.mdAndUp ? "Фильтры" : "" }}
            <v-badge primary offset-y="6" dot :value="filtersActive">
              <v-icon :right="$vuetify.breakpoint.mdAndUp"
                >mdi-tune-variant</v-icon
              >
            </v-badge>
          </v-btn>
        </div>
      </div>
      <!-- </div> -->
      <v-expand-transition>
        <div v-if="showFIlters && $vuetify.breakpoint.mdAndUp">
          <OCFilterBlock
            :filters="filters"
            :selected-region="selectedRegion"
            :type="typeFilters"
            @update:type="$emit('update:type-filters', $event)"
            :options="options"
            :background="background"
            @update:options="$emit('update:options', $event)"
          />
        </div>
      </v-expand-transition>

      <v-bottom-sheet
        transition="dialog-bottom-transition"
        v-model="showFIlters"
        v-if="!$vuetify.breakpoint.mdAndUp"
        scrollable
      >
        <v-card>
          <v-card-title>
            <div class="d-flex w-100">
              <h3>Настройки</h3>
              <v-spacer />
              <v-btn icon @click="showFIlters = false" large
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text style="max-height: 80vh;">
            <OCFilterBlock
              zone
              :filters="filters"
              :selected-region="selectedRegion"
              @update:selectedRegion="selectedRegion = $event"
              :type="typeFilters"
              @update:type="$emit('update:type-filters', $event)"
              :options="options"
              @update:options="
                showFIlters = false;
                $emit('update:options', $event);
              "
            />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </v-container>
  </v-form>
</template>

<script>
import ZoneSelector from "@/components/ZoneSelector";
import OCFilterBlock from "@/components/OCFilterBlock";
import {processFilterObject} from "@/utils/filterOptions";
import {defaultFilterDefinitions} from "@/assets/filters";

export default {
  components: { OCFilterBlock, ZoneSelector },
  props: {
    text_query: {
      default: ""
    },
    region: {
      type: Object
    },
    filters: {
      type: Object
    },
    typeFilters: {
      default: false
    },
    options: {}
  },
  data() {
    return {
      showFIlters: false,
      regions: null
    };
  },
  computed: {
    filtersActive() {
      return processFilterObject(this.options, defaultFilterDefinitions).length > 0;
    },
    background() {
      return !this.$vuetify.theme.isDark
        ? "white"
        : "rgba(255, 255, 255, 0.08)";
    },
    selectedRegion: {
      get() {
        return this.region;
      },
      set(v) {
        this.$emit("update:region", v);
      }
    },
    query: {
      get() {
        return this.text_query;
      },
      set(v) {
        this.$emit("update:text_query", v);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    buttonColor() {
      if (this.showFIlters) return "primary";
      return this.$vuetify.theme.isDark ? "white" : "black";
    }
  }
};
</script>

<style lang="scss">
.custom-field-border-behavior {
  fieldset {
    transition-property: color !important; // exclude border-width from transition properties
    border-width: 0px !important; // remove default 1px border for outlined fields
  }
  &:hover fieldset {
    border-width: thin !important;
  }
  &:hover {
    border-width: thin !important;
  }
  &.v-input--is-focused fieldset {
    border-width: 2px !important;
  }
}

.custom-button-border-behavior {
  border-width: 0 !important;
  &:hover {
    outline: 1px currentColor solid;
    outline-offset: -1px;
  }
  &:focus {
    outline: 2px currentColor solid;
  }
  // &.active {
  //   outline: 1px var(--v-primary-base) solid;
  //   outline-offset: -1px;
  // }
}

.search-bar {
  display: flex;
  width: 100%;
  // flex-wrap: wrap;
  .search-filters-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-width: 0px;
    // border-left-width: 0;
    border-color: currentColor;
    border-style: solid;
    // outline:2px #fff solid;
    font-weight: normal;
    &:focus {
      border-width: 2px;
    }
  }
  .search-field {
    flex-grow: 5;
    border-width: 0px !important;
    .mobile & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.mobile .search-field {
    // width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
