import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"drawer-z-index-1 test",attrs:{"mini-variant":_vm.config.mini,"cv-model":"drawerShow","value":_vm.config.show,"app":"","temporary":_vm.config.temporary,"bottom":"","mobile-breakpoint":"0","floating":"","expand-on-hover":_vm.config.mini},on:{"input":_vm.setValue},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._t("header")]},proxy:true},{key:"append",fn:function(){return [_c('div',{staticClass:"nav-drawer-footer"},[(!_vm.config.mini)?_c('div',{staticClass:"nav-drawer-footer__inner d-flex justify-center align-center body-2"},[_c('a',[_vm._v("Помощь")]),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-circle-small")]),_c('a',[_vm._v("Условия")]),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-circle-small")]),_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" Ещё "),_c(VIcon,{staticClass:"ml-n1",attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1713697333)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"link":""}},[_vm._v("Приватность")]),_c(VListItem,{attrs:{"link":""}},[_vm._v("Cookies")]),_c(VListItem,{attrs:{"link":""}},[_vm._v("Состояние")]),_c(VListItem,{attrs:{"link":""}},[_vm._v("Разработчикам")]),_c(VListItem,{attrs:{"link":""}},[_vm._v("О проекте")])],1)],1)],1):_c('div',[_c(VBtn,{attrs:{"icon":"","tile":"","block":"","height":"48px"},on:{"click":function($event){return _vm.$emit('input', true)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)])]},proxy:true}],null,true)},[_c('drawer-menu',{staticClass:"drawer-menu",attrs:{"shorten":_vm.config.mini}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }