import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import Ripple from "vuetify/lib/directives/ripple";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

const treesIcon = {
  template: `<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="128" x2="128" y1="232" y2="88"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="128" x2="80" y1="152" y2="128"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="128" x2="176" y1="128" y2="104"/><path d="M132.5,181.2A64,64,0,1,0,194.7,69.9a8.6,8.6,0,0,1-4-4.2,68,68,0,0,0-125.4,0,8.6,8.6,0,0,1-4,4.2,64,64,0,1,0,62.2,111.3A8.2,8.2,0,0,1,132.5,181.2Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>`
};

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", //Currently using webfonts (/public/index.html)
    values: {
      treesIcon: {
        component: treesIcon
      }
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: window.matchMedia?.("(prefers-color-scheme: dark)").matches ?? false,
    themes: {
      light: {
        primary: "#109CF1",
        accent: "#F36270",
        secondary: "#30b1dc",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252"
      },
      dark: {
        primary: "#4CAF50",
        // primary: "#7cb342",
        // primary: "#4ccfae",
        // primary: "#00BB8C",
        accent: "#dd4254"
      }
    }
  },
  lang: {
    locales: { ru },
    current: "ru"
  }
});
