<template>
  <v-toolbar class="action-bar pa-0" flat dense>
    <v-container class="pa-0 d-flex align-center" style="max-width:1280px">
      <!--  -->
      <slot v-bind="{ onlyOneSelected, someSelected, noneSelected }" />

      <template v-if="!custom">
        <v-expand-x-transition>
          <template v-if="someSelected">
            <div class="d-flex">
              <!--  -->
              <slot name="someSelected" />
              <!--  -->
              <v-expand-x-transition>
                <div class="d-flex" v-if="onlyOneSelected">
                  <!--  -->
                  <slot name="onlyOneSelected" />
                  <!--  -->
                </div>
              </v-expand-x-transition>
            </div>
          </template>
        </v-expand-x-transition>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="v-btn--hover" icon>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!--  -->
            <slot
              name="menu"
              v-bind="{ onlyOneSelected, someSelected, noneSelected }"
            />
            <!--  -->
            <v-list-item
              v-if="noneSelected"
              class="font-italic font-weight-light"
            >
              Выделите записи чтобы увидеть действия
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer />
        <slot name="append" />
      </template>
    </v-container>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    selected: {
      default: []
    },
    custom: {
      default: false
    }
  },
  computed: {
    onlyOneSelected() {
      return this.$props.selected.length === 1;
    },
    someSelected() {
      return this.$props.selected.length > 0;
    },
    noneSelected() {
      return this.$props.selected.length === 0;
    }
  }
};
</script>

<style>
.action-bar .v-toolbar__content {
  padding: 0;
}
</style>
