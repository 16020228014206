<template>
  <div>
    <div class="text-center ma-10">Страница не найдена</div>
  </div>
</template>

<script>
import PanelFrame from "@/components/PanelFrame";

export default {
  name: "Home",
  data: () => ({
    ssss: null
  }),
  created() {
    this.$emit("update:layout", PanelFrame);
  }
};
</script>
