export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      updateLoading: false
    };
  },

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    });

    document.addEventListener("swUpdating", this.updateIsLoading, {
      once: true
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },

    updateIsLoading(event) {
      this.updateLoading = true;
    },

    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
};
