const generaBush = [
  {
    lat: "",
    name: "Микробиота",
    species: ["перекрестнопарная"]
  },
  {
    lat: "",
    name: "Можжевельник",
    species: ["казацкий", "горизонтальный", "скальный", "обыкновенный"]
  },
  {
    lat: "",
    name: "Сосна",
    species: ["горная"]
  },
  {
    lat: "",
    name: "Туя",
    species: ["западная"]
  },
  {
    lat: "",
    name: "Арония",
    species: ["Мичурина"]
  },
  {
    lat: "",
    name: "Барбарис",
    species: ["обыкновенный", "Тунберга"]
  },
  {
    lat: "",
    name: "Бересклет",
    species: ["европейский"]
  },
  {
    lat: "",
    name: "Боярышник",
    species: [
      "сибирский (кроваво-красный)",
      "полумягкий",
      "алтайский",
      "крупноколючковый",
      "перистонадрезанный"
    ]
  },
  {
    lat: "",
    name: "Гортензия",
    species: ["древовидная", "метельчатая"]
  },
  {
    lat: "",
    name: "Дерен",
    species: ["белый"]
  },
  {
    lat: "",
    name: "Ива",
    species: ["пурпурная"]
  },
  {
    lat: "",
    name: "Калина",
    species: ["обыкновенная"]
  },
  {
    lat: "",
    name: "Кизильник",
    species: ["блестящий"]
  },
  {
    lat: "",
    name: "Лапчатка",
    species: ["кустарниковая"]
  },
  {
    lat: "",
    name: "Лох",
    species: ["серебристый"]
  },
  {
    lat: "",
    name: "Магония",
    species: ["падуболистная"]
  },
  {
    lat: "",
    name: "Мирикария",
    species: ["лисохвостниковая"]
  },
  {
    lat: "",
    name: "Пузыреплодник",
    species: ["калинолистный"]
  },
  {
    lat: "",
    name: "Роза",
    species: ["сизая", "бедренцоволистная (колючейшая)", "морщинистая"]
  },
  {
    lat: "",
    name: "Рябинник",
    species: ["рябинолистный"]
  },
  {
    lat: "",
    name: "Сирень",
    species: ["венгерская", "обыкновенная"]
  },
  {
    lat: "",
    name: "Снежноягодник",
    species: ["белый"]
  },
  {
    lat: "",
    name: "Спирея",
    species: [
      "аргута (снежная)",
      "березолистная",
      "дубровколистная",
      "Бумальда",
      "серая",
      "японская",
      "иволистная"
    ]
  },
  {
    lat: "",
    name: "Смородина",
    species: ["альпийская", "золотистая"]
  },
  {
    lat: "",
    name: "Чубушник",
    species: ["венечный"]
  },
  {
    lat: "",
    name: "Виноград",
    species: ["Девичий виноград"]
  },
  {
    lat: "",
    name: "Жимолость",
    species: ["каприфоль"]
  },
  {
    lat: "",
    name: "Клематис",
    species: ["мелкоцветковый"]
  }
];

export default generaBush;

export const species = generaBush.reduce(
  (ar, el) => [
    ...ar,
    ...el.species.map(value => ({
      lat: "",
      name: el.name + " " + value,
      value: el.name + " " + value,
      parent: el
    }))
  ],
  []
);
