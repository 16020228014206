<template>
  <v-list style="" dense shaped>
    <drawer-menu-item
      v-for="item in items"
      :key="item.title"
      :item="item"
      :show-group="!shorten"
    />
  </v-list>
</template>

<script>
import DrawerMenuItem from "./MenuItem";
import opencity from "../../service/opencity";

export default {
  components: {
    DrawerMenuItem
  },
  props: {
    shorten: {
      default: false
    }
  },
  data: () =>
    opencity.isAdmin()
      ? {
          items: [
            {
              title: "Главная",
              icon: "mdi-view-dashboard-outline",
              to: { name: "Dashboard" }
            },
            {
              title: "Сервисы",
              icon: "mdi-apps",
              isList: true,
              children: [
                {
                  title: "Социальные объекты",
                  icon: "",
                  to: { name: "Objects" }
                },
                {
                  title: "Работы по адаптации",
                  icon: ""
                },
                {
                  title: "Проблемы и решения",
                  icon: ""
                }
              ]
            },
            {
              title: "Карта объектов",
              icon: "mdi-map-search-outline",
              to: { name: "ObjectsMap" }
            },
            {
              title: "Люди",
              icon: "mdi-account-outline",
              count: 0,
              to: { name: "People" }
            },
            {
              title: "Команды",
              icon: "mdi-account-group-outline",
              count: 12,
              to: { name: "Teams" }
            },
            {
              title: "Флажки",
              icon: "mdi-flag-outline",
              count: 7
            },
            {
              title: "Новости",
              icon: "mdi-newspaper-variant-outline",
              count: 0
            },
            {
              title: "Диалоги",
              icon: "mdi-message-processing-outline"
            },
            {
              title: "База знаний",
              icon: "mdi-information-variant"
            },
            {
              title: "Настройки",
              icon: "mdi-cog-outline",
              count: 0,
              isList: true,
              children: [
                {
                  title: "Регионы",
                  icon: "",
                  to: { name: "Regions" }
                },
                {
                  title: "Сервисы",
                  icon: ""
                },
                {
                  title: "Категории",
                  icon: ""
                },
                {
                  title: "Рабочие области",
                  icon: "",
                  to: { name: "WorkAreas" }
                },
                {
                  title: "Параметры",
                  icon: ""
                },
                {
                  title: "Роли",
                  icon: "",
                  to: { name: "Roles" }
                },
                {
                  title: "Проекты",
                  icon: ""
                },
                {
                  title: "Виджеты",
                  icon: ""
                },
                {
                  title: "Directories",
                  icon: ""
                },
                {
                  title: "Настройки системы",
                  icon: ""
                }
              ]
            }
          ]
        }
      : {
          items: [
            {
              title: "Главная",
              icon: "mdi-view-dashboard-outline",
              to: { name: "Dashboard" }
            },
            {
              title: "Сервисы",
              icon: "mdi-apps",
              isList: true,
              children: [
                {
                  title: "Зеленый фонд",
                  icon: "",
                  to: { name: "Objects" }
                },
                {
                  title: "Объекты на модерации",
                  icon: "",
                  to: { name: "ObjectsModeration" }
                }
                // {
                //   title: "Работы по озеленению",
                //   icon: ""
                // },
                // {
                //   title: "Проблемы и решения",
                //   icon: ""
                // },
                // {
                //   title: "Акции и мероприятия",
                //   icon: ""
                // }
              ]
            },
            {
              title: "Карта объектов",
              icon: "mdi-map-search-outline",
              to: { name: "ObjectsMap" }
            },
            // {
            //   title: "Диалоги",
            //   icon: "mdi-message-processing-outline"
            // },
            {
              title: "База знаний",
              icon: "mdi-information-variant"
            }
          ]
        }
};
</script>

<style>
/* .v-list--dense .v-list-item__action:first-child,
.v-list--dense .v-list-item__icon:first-child {
  margin-right: 16px;
} */
</style>
