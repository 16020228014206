import Vue from "vue";
import VueRouter from "vue-router";
import opencity from "../service/opencity";
import routes from "./routes";

Vue.use(VueRouter);

opencity.restoreLogin();

let redirectTo = { name: "Home" };


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path == from.path) {
      return null;
    }
    return savedPosition ?? { x: 0, y: 0 };
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.type === VueRouter.NavigationFailureType.duplicated) {
      return;
    }
    throw err;
  });
};

router.beforeEach((to, from, next) => {
  if (opencity.authenticated || to.name === "Login") next();
  else if (from.name === "Login") {
    if (redirectTo?.path === "/login") next({ name: "Home" });
    else next(redirectTo);
  } else {
    redirectTo = to;
    next("/login");
  }
});

export default router;
