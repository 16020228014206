<template>
  <div
    class="drop-area-main drop-area d-flex flex-column justify-center align-center"
  >
    <v-icon size="48" style="opacity: 0.5"> mdi-camera-plus</v-icon>
    <span
        class="text-center mt-2"
        style="max-width: 340px"
    >
      Добавить фото
    </span>
  </div>
</template>
<script>
export default {
  name: 'AddPhotoArea'
}
</script>
<style lang="scss" scoped>
.drop-area-main {
  width: 100%;
  padding: 8px;
  height: 100%;
  //@media (min-width: 960px) {
  //  height: 400px;
  //}
}

.drop-area {
  border: 2px dashed #26323899;
  //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%230000009C' stroke-width='4' stroke-dasharray='2%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    border-color: #263238bb;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>