<template>
  <v-data-table
    class="table-layout"
    :item-key="rowKey"
    show-select
    calculate-widths
    @item-selected="itemSelectHandler"
    @click.right.prevent
    :footer-props="{
      'items-per-page-options': [10, 20, 30]
    }"
    @input="inputHandler"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:body.prepend="{ select, isSelected }">
      <template v-if="pinned.length">
        <component
          :is="rowComponent"
          class="pinned-row"
          v-for="item in pinned"
          :key="item.id"
          @click="rowClickHandler"
          @dblclick="rowDbclickHandler"
          :item="item"
          :select="select.bind(this, item)"
          :is-selected="isSelected(item)"
        />
        <tr class="row-divider">
          <td style=" height: 21px" :colspan="headers.length + 1" />
        </tr>
      </template>
    </template>

    <template v-slot:item="{ item, select, isSelected }">
      <component
        :is="rowComponent"
        @click="rowClickHandler"
        @dblclick="rowDbclickHandler"
        :item="item"
        :select="select"
        :is-selected="isSelected"
      />
    </template>
  </v-data-table>
</template>

<script>
import datetime from "@/utils/datetime";

const debounceTime = 500;

// const EmptyRow = {
//   render: c =>
//     c("tr", [
//       c("td", {
//         style: {
//           pointerEvents: "none",
//           height: "42px",
//           borderBottom: "thin solid rgba(0, 0, 0, 0.12)"
//         },
//         attrs: {
//           colspan: "8"
//         }
//       })
//     ])
// };

import Row from "vuetify/lib/components/VDataTable/Row";

export default {
  props: {
    rowKey: {
      default: "id"
    },
    pinned: {
      default: []
    },
    rowComponent: {
      default: Row
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    clickDebouncer: {}
  }),
  filters: {
    datetime
  },
  methods: {
    callback(e) {
      // console.log(e);
    },
    rowClickHandler(item) {
      if (this.clickDebouncer[item.id]) {
        return this.rowDbclickHandler(item);
      }
      this.$emit("click:row", item);
      this.clickDebouncer[item.id] = true;
      setTimeout(() => {
        this.$set(this.clickDebouncer, item.id, false);
      }, debounceTime);
    },
    rowDbclickHandler(item) {
      this.$emit("dbclick:row", item);
    },
    itemSelectHandler(item) {
      this.$emit("item-selected", item);
    },
    inputHandler(payload) {
      this.$emit("input", payload);
    }
  },
  inheritAttrs: false
};
</script>

<style lang="scss">
.v-data-table__checkbox {
  opacity: 0.7;
}

.theme--light {
  .table-block {
    background: white;
  }

  .theme--light.v-data-table
    tbody
    tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgba(0, 0, 0, 0.04);
    .v-data-table__checkbox {
      opacity: 1;
      position: relative;
      &::before {
        font: normal normal normal 26px/1 "Material Design Icons";
        content: "\F01DD";
        // font-feature-settings: 'liga';
        position: absolute;
        left: -19px;
        top: -1px;
        opacity: 0.47;
      }
    }
  }

  .v-data-table__focus {
    // text-decoration: underline;
    outline: 2px solid var(--v-primary-base);
    //background: rgba(0, 0, 0, 0.085) !important;

    //  background: rgba(var(--primary-rgb), 0.12) !important;
    // color: var(--v-primary-base);
    //   .v-icon {
    //     color: currentColor ;
    //   }
  }
}

.theme--dark {
  .table-block {
    background: #1e1e1e;
  }

  .theme--dark.v-data-table
    tbody
    tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgba(255, 255, 255, 0.2);
    .v-data-table__checkbox {
      opacity: 1;
      position: relative;
      &::before {
        font: normal normal normal 26px/1 "Material Design Icons";
        content: "\F01DD";
        // font-feature-settings: 'liga';
        position: absolute;
        left: -19px;
        top: -1px;
        opacity: 0.45;
      }
    }
  }

  .v-data-table__focus {
    // text-decoration: underline;
    outline: 2px solid var(--v-primary-base);
    position: relative;
    //background: rgba(255, 255, 255, 0.3) !important;
    //  background: rgba(var(--primary-rgb), 0.12) !important;
    // color: var(--v-primary-base);
    //   .v-icon {
    //     color: currentColor ;
    //   }
  }
}

.row-divider td {
  pointer-events: none;
  height: 21px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.no-border-bottom-test td {
  border-bottom: none !important;
}

.table-layout {
  user-select: none;
  table-layout: fixed;
  // overflow-x: auto;
  width: 100%;
  // border-bottom: thin solid rgba(0, 0, 0, 0.12);

  //https://css-tricks.com/flexbox-truncated-text/
  min-width: 0;

  &.v-data-table td {
    height: 42px;
  }

  tr.v-data-table__selected.v-data-table__focus {
    background: rgba(var(--primary-rgb), 0.2) !important;
  }
  tr {
    &::after {
      content: "";
      pointer-events: none;
      opacity: 0;
    }

    &.v-data-table__selected {
      background: rgba(var(--primary-rgb), 0.12) !important;
      color: var(--v-primary-base);
      &:hover {
        background: rgba(var(--primary-rgb), 0.22) !important;
      }
      .v-icon {
        color: currentColor;
      }
      .v-data-table__checkbox {
        opacity: 1;
      }
    }
  }
}

.table-layout tr {
  cursor: pointer;
}

.table-layout th {
  white-space: nowrap;
}

tr.pinned-row td:first-child {
  border-left: 4px solid rgb(255, 179, 0);
  padding: 0 0 0 12px !important;
}
</style>
