<template>
  <OCMapButton
    title="Поиск по адресу"
    @click="focused = true"
    v-if="foldable && !focused"
  >
    <v-icon>mdi-magnify</v-icon>
  </OCMapButton>
  <v-autocomplete
    v-else
    :autofocus="foldable"
    @blur="focused = false"
    solo
    dense
    hide-details
    class="ma-1"
    prepend-inner-icon="mdi-magnify"
    height="40"
    placeholder="Поиск по адресу"
    :search-input.sync="searchText"
    :loading="loadingAutocomplete"
    :items="autocompleteResults"
    @input="autocompleteSelect"
    return-object
    item-text="value"
    no-filter
    hide-no-data
    append-icon=""
    clearable
  >
  </v-autocomplete>
</template>

<script>
import OCMapButton from "@/components/map/OCMapButton.vue";

const DEBOUNCE_TIME = 200;

export default {
  props: {
    foldable: {
      default: false,
      type: Boolean
    },
    expanded: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    focused: false,
    timeout: null,
    showAutocompleteResults: false,
    autocompleteResults: [],
    pendingAutocompletes: 0,
    searchText: ""
  }),
  inject: ["map"],
  computed: {
    loadingAutocomplete() {
      return !!(this.pendingAutocompletes || this.timeout);
    }
  },
  watch: {
    focused() {
      this.$emit('update:expanded', this.focused);
    },
    searchText() {
      console.log("AARG", this.searchText)
      this.onSearchFieldChange()
    }
  },
  methods: {
    async updateAutocomplete(searchText) {
      if ((searchText?.length ?? 0) < 3) {
        this.autocompleteResults = [];
        return;
      }

      this.pendingAutocompletes += 1;

      const data = await fetch(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + process.env.VUE_APP_DADATA_KEY
          },
          body: JSON.stringify({
            query: searchText,
            count: 5,
            locations: [{ city: "Пермь" }],
            restrict_value: true,
            from_bound: { value: "settlement" },
            to_bound: { value: "house" }
          })
        }
      ).then(res => res.json());

      this.pendingAutocompletes -= 1;
      this.autocompleteResults = data.suggestions;
    },
    clearSearchText() {
      this.searchText = "";
      this.onSearchFieldChange();
    },
    onSearchFieldChange() {
      if ((this.searchText?.length ?? 0) < 3) {
        this.autocompleteResults = [];
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = null;
        return;
      }

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.updateAutocomplete(this.searchText);
      }, DEBOUNCE_TIME);
    },
    autocompleteSelect(prediction) {
      this.searchText = "";
      this.$emit("input", {
        raw: prediction,
        lat: prediction.data.geo_lat,
        lng: prediction.data.geo_lon
      });

      this.map?.setCenter([
        prediction.data.geo_lon,
        prediction.data.geo_lat
      ]);
      this.map?.setZoom(17);
    }
  },
  name: "OCMapLocationSearch",
  components: { OCMapButton }
};
</script>
<style lang="scss">
$mobilePaddingRight: 40px;
$mobilePaddingTop: 8px;
</style>
