<template>
  <div>
    <MglGeojsonLayer
      v-if="highlight"
      :layer="highlightLayer"
      layer-id="highlightLyr"
      :source="highlightSource"
      source-id="highlight"
      ref="highlightLayer"
    />

    <MglGeojsonLayer
      v-if="highlight"
      :layer="highlightLayerOutline"
      layer-id="highlightLyrOutline"
      :source="highlightSource"
      source-id="highlight"
      ref="highlightLayerOutline"
    />

    <MglVectorLayer
      v-for="vectorLayer in mapVectorLayers"
      :key="vectorLayer.id"
      :source="vectorLayer.source"
      :layer="vectorLayer.layer"
      :source-id="vectorLayer.sourceId"
      :layer-id="vectorLayer.id + 'layer'"
      ref="layers"
      :clear-source="false"
    />

    <MglVectorLayer
      v-for="activeLyr in mapActiveLayersFiltered"
      :key="activeLyr.id"
      :layer="activeLyr.layer"
      :layer-id="activeLyr.id"
      :source="activeLyr.source"
      :source-id="activeLyr.sourceId"
      ref="activeLayers"
      :clear-source="false"
    />
  </div>
</template>
<script>
import opencity from "@/service/opencity";
import { MglVectorLayer, MglGeojsonLayer } from "vue-mapbox";

import difference from "@turf/difference";
import useClassifier from "@/mixins/useClassifier";

function getTileLayer(cat, options = {}) {
  const source = opencity.getTilesUrl(cat.id, options);
  const queryString = source.split("?")?.[1] ?? "";

  const variant = options?.variants?.[cat.id] ?? 0;
  let styleDef = cat.style.layer[variant];
  if (!Array.isArray(styleDef)) styleDef = [styleDef];

  styleDef.forEach(el => {
    el['source-layer'] = cat.id.toString();
    if (cat.style?.minZoom) el.minzoom = cat.style?.minZoom;
  });

  return styleDef.map((el, i) => ({
    layer: el,
    minzoom: cat.style?.minZoom ?? 0,
    source: {
      type: "vector",
      tiles: [source],
      // minzoom: cat.style?.minZoom ?? 0
    },
    sourceId: "dataSource" + queryString,
    id: "data" + cat.id + "v" + variant + "n" + i + queryString
  }));
}

function isTouchScreen() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

function getActiveLayer(cat, variant, options = {}) {
  const source = opencity.getTilesUrl(cat.id, options);
  const queryString = source.split("?")?.[1] ?? "";
  if (cat.style.active) cat.style.active["source-layer"] = cat.id.toString();
  return {
    layer: cat.style.active,
    source: {
      type: "vector",
      tiles: [source]
    },
    sourceId: "dataSource" + queryString,
    id: "data" + cat.id + "Active"
  };
}

export default {
  name: "OCLayerManageer",
  props: {
    options: {},
    category: {},
    topic: {},
    activeItemIds: {},
    highlight: {}
  },
  data: () => ({
    activeLayers: []
  }),
  components: {
    MglVectorLayer,
    MglGeojsonLayer
  },
  watch: {
    mapVectorLayers() {
      this.reorderLayers();
    },
    editingInfo: {
      immediate: true,
      handler(val) {
        if (val && val.type !== "Point") {
          this.initDraw(val);
        }
      }
    }
  },
  inject: ["map"],
  methods: {
    reorderLayers() {
      console.log("CALL TO REORDER")
      // Update layer order according to the provided array order
      this.$nextTick(() => {
        this.$refs.highlightLayer?.move();
        this.$refs.highlightLayerOutline?.move();
        this.mapVectorLayers.forEach(el =>
          this.$refs.layers
            ?.filter(l => l.$vnode.key === el.id)
            .forEach(el => el.move())
        );
        this.$refs.activeLayers?.forEach(lyr => lyr.move());
      });
      this.$emit('reorderLayers')
    },
    queryFeatures(bbox) {
      const layers = this.mapVectorLayers
        .filter(el => !el.layer.clickThrough)
        .map(el => el.id + "layer");
      return this.map.queryRenderedFeatures(bbox, { layers }).reverse();
    }
  },
  mixins: [useClassifier],
  computed: {
    highlightLayer() {
      return {
        type: "fill",
        paint: {
          "fill-opacity": 0.3
        }
      };
    },
    highlightLayerOutline() {
      return {
        type: "line",
        paint: {
          "line-width": 2,
          "line-color": "white"
        }
      };
    },
    highlightSource() {
      return (
        this.highlight && {
          type: "geojson",
          data: difference(
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [-180, -90],
                    [-180, 90],
                    [180, 90],
                    [180, -90],
                    [-180, -90]
                  ]
                ]
              }
            },
            {
              type: "Feature",
              geometry: this.highlight
            }
          )
        }
      );
    },
    allTypes() {
      return this.OCClassifier.map(cat => cat.objectTypes)
        .flat()
        .sort((a, b) => (b.style.minZoom ?? 0) - (a.style.minZoom ?? 0));
    },
    visibleTypes() {
      const { category, type, types } = this.options;
      const topic = type;
      if (types && types.length && types[0] != 0) {
        return this.allTypes.filter(
          el => !el.style.disabled && types.includes(el.id)
        );
      }
      if (!category) {
        return this.allTypes.filter(el => !el.style.disabled);
      }
      if (!topic) {
        return category.objectTypes.filter(el => !el.style.disabled);
      }
      return [topic];
    },
    mapVectorLayers() {
      const options = {
        query: this.options.query,
        dataFilters: this.options.dataFilters
      };

      for (let key in this.options) {
        if (key.startsWith("tag~")) {
          options.dataFilters[key.slice(4)] = this.options[key];
        }
      }

      if (this.options.zone?.type === "region") {
        // options.regions = this.options.zone?.children.map(el => el.id);
        options.regions = [this.options.zone.id];
      } else if (this.options.zone?.type === "object") {
        options.parent = this.options.zone.id;
      }
      options.createdFrom = this.options.created?.from;
      options.createdTo = this.options.created?.to;
      options.updatedFrom = this.options.updated?.from;
      options.updatedTo = this.options.updated?.to;

      options.variants = this.options.variants;

      options.author = this.options.author;
      options.visible = this.options.visibility;
      options.list = this.options.list;

      options.moderated = this.options.moderated;
      // options.types = this.options.types;

      let result = this.visibleTypes.reduceRight((o, type) => {
        const lyr = getTileLayer(type, options);
        const a = options?.variants?.[type.id];
        for (let l of lyr) {
          if (this.options.exclude)
            l.layer.filter = [
              "!",
              ["in", ["id"], ["literal", this.options.exclude]]
            ];
          else {
            delete l.layer.filter;
          }
          if (l.layer.type) o.push(l);
        }
        return o;
      }, []);

      if (this.options.showUnmoderated) {
        result.push({
          layer: {
            type: "circle",
            paint: {
              "circle-color": "#ff0",
              "circle-radius": 4,
              "circle-opacity": [
                "case",
                [
                  "coalesce",
                  ["feature-state", "selected"],
                  ["feature-state", "hover"],
                  false
                ],
                1,
                0.8
              ],
              "circle-stroke-color": [
                "case",
                ["boolean", ["feature-state", "selected"], false],
                "red",
                "black"
              ],
              "circle-stroke-width": [
                "case",
                ["boolean", ["feature-state", "selected"], false],
                4,
                2
              ],
              "circle-stroke-opacity": [
                "case",
                [
                  "coalesce",
                  ["feature-state", "selected"],
                  ["feature-state", "hover"],
                  false
                ],
                1,
                0.5
              ]
            },
            "source-layer": "1"
          },
          source: {
            type: "vector",
            tiles: [
              opencity.API_BASE + "/objects/layer/1/{z}/{x}/{y}?moderated=false"
            ],
            minzoom: 14
          },
          sourceId: "data1moderated=false",
          id: "data1v0n0moderated=false"
        });
      }
      return result;
    },
    mapActiveLayers() {
      return this.allTypes.reduceRight((o, type) => {
        const lyr = getActiveLayer(
          type,
          this.options.variants?.[type.id],
          this.options.moderated === false ? { moderated: false } : undefined
        );
        if (lyr.layer?.type) o.push(lyr);
        return o;
      }, []);
    },
    mapActiveLayersFiltered() {
      this.activeLayers = this.mapActiveLayers.map(el => {
        this.$set(el, "layer", el.layer);
        this.$set(el.layer, "filter", [
          "in",
          ["id"],
          ["literal", this.activeItemIds?.map(el => el.id) ?? []]
        ]);
        return { ...el };
      });
      return this.activeLayers;
    }
  }
};
</script>
