import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.media && _vm.media.length && _vm.carousel)?_c(VCarousel,{ref:"carousel",staticClass:"my-4 rounded",attrs:{"hide-delimiters":"","show-arrows":_vm.media.length > 1 || (_vm.media.length > 0 && _vm.allowAdd),"show-arrows-on-hover":"","touch":{
    left: function () { return _vm.$refs.carousel.next(); },
    right: function () { return _vm.$refs.carousel.prev(); }
  },"height":_vm.height},model:{value:(_vm.currentSlide),callback:function ($$v) {_vm.currentSlide=$$v},expression:"currentSlide"}},[_vm._l((_vm.media),function(cur,i){return _c(VCarouselItem,{key:cur.id || cur.src,staticClass:"grey lighten-2",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.imagePreviewUrl(i)},on:{"click":function($event){return _vm.openGallery(i)}}})}),(_vm.allowAdd)?_c(VCarouselItem,{key:"last",on:{"click":function($event){return _vm.$emit('add')}}},[_c('AddPhotoArea')],1):_vm._e(),_c(VSheet,{staticClass:"rounded pa-1 d-flex align-center elevation-0",staticStyle:{"position":"absolute","right":"8px","bottom":"8px","background-color":"#00000088"},attrs:{"dark":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-camera-outline")]),_c('span',{staticClass:"text-body-2",domProps:{"textContent":_vm._s(
        (_vm.currentSlide === _vm.media.length
          ? '+'
          : _vm.currentSlide + 1) + ' / ' + _vm.media.length
      )}})],1)],2):(_vm.media && _vm.media.length)?_c(VImg,{staticClass:"my-4 rounded",attrs:{"height":_vm.height,"src":_vm.imagePreviewUrl(_vm.currentSlide)},on:{"click":function($event){return _vm.openGallery(_vm.currentSlide)}}},[_c(VSheet,{staticClass:"rounded pa-1 d-flex align-center elevation-0",staticStyle:{"position":"absolute","right":"8px","bottom":"8px","background-color":"#00000088"},attrs:{"dark":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-camera-outline")]),_c('span',{staticClass:"text-body-2",domProps:{"textContent":_vm._s(_vm.media.length)}})],1)],1):(_vm.allowAdd)?_c('AddPhotoArea',{style:({ height: _vm.height + 'px' }),on:{"click":function($event){return _vm.$emit('add')}}}):_c('NoPhoto',{staticClass:"my-4",attrs:{"height":_vm.height}})}
var staticRenderFns = []

export { render, staticRenderFns }