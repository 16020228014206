import opencity from "@/service/opencity";

class OpenCityPanel {
  opencity = null;

  constructor(opencityService) {
    this.opencity = opencityService;
  }

  defaultPanelPreferences = {
    darkMode: "auto",
    animations: true
  };

  usePanelPreferences(cb) {
    cb({
      ...this.defaultPanelPreferences,
      ...opencity.getPreferences("panel")
    });
    opencity.on("update:preferences[panel]", val =>
      cb({
        ...this.defaultPanelPreferences,
        ...val
      })
    );
  }
}

export default new OpenCityPanel(opencity);
