<template>
  <div>
    <v-container fluid class="head px-3" style="max-width:1280px">
      <v-row>
        <v-col class="d-flex align-center pb-0 mt-5">
          <div class="d-flex align-baseline">
            <span class="text-h4 font-weight-medium">Объекты на модерации</span>
            <router-link
                v-if="$vuetify.breakpoint.mdAndUp"
                class="text-decoration-none ml-6"
                :to="mapRoute"
                text
            >На карте</router-link
            >
            <router-link
                v-else
                class="text-decoration-none ml-4"
                :to="mapRoute"
                icon
            ><v-icon style="vertical-align:0px" color="primary"
            >mdi-map-outline</v-icon
            ></router-link
            >
          </div>

          <v-spacer />

<!--          <secondary-button-->
<!--            v-if="$vuetify.breakpoint.smAndUp"-->
<!--            style="width: 160px"-->
<!--            :to="{ name: 'ObjectCreate' }"-->
<!--            >Добавить объект</secondary-button-->
<!--          >-->
<!--          <v-menu v-else offset-y>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-btn-->
<!--                width="46"-->
<!--                height="46"-->
<!--                icon-->
<!--                class="ml-2 v-btn&#45;&#45;hover"-->
<!--                style="margin-right: -8px"-->
<!--                v-on="on"-->
<!--              >-->
<!--                <v-icon size="28">mdi-dots-vertical</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item-->
<!--                link-->
<!--                v-if="!$vuetify.breakpoint.smAndUp"-->
<!--                to="/service/objects/create"-->
<!--              >-->
<!--                <v-list-item-title>Добавить объект</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              &lt;!&ndash; <v-list-item>Пригласить</v-list-item> &ndash;&gt;-->
<!--            </v-list>-->
<!--          </v-menu>-->
        </v-col>
      </v-row>
      <v-row class="mt-4" v-if="false">
        <v-col class="py-1">
          <tab-chip-group :values="['Все', 'Публичные', 'Скрытые']" />
        </v-col>
      </v-row>
      <v-row class="mx-n3 mx-md-n3">
        <v-col class="py-1 px-0">
          <!--          <v-chip-group center-active :value="[]" multiple>-->
          <div class="px-2">
            <v-badge
                primary
                offset-y="12"
                offset-x="20"
                bordered
                :content="layerCount || '0'"
                v-if="filters.advanced"
            >
              <v-btn class="mx-2" @click="showLayerEdit = true">
                <v-icon size="24" left>mdi-layers-outline</v-icon> Слои
              </v-btn>
            </v-badge>
            <v-chip
                v-for="chip in chips"
                :color="$vuetify.theme.isDark ? null : '#F5F6F8'"
                :key="chip.type + chip.id"
                :close="chip.type === 'layer' || (chip.type === 'cat' ? !!cat : !!type)"
                :class="{ success: chip.type === 'layer' || (chip.type === 'cat' ? cat : type) }"
                close-icon="mdi-close"
                class="ma-1 rounded-lg"
                @click="chipClick(chip)"
                @click:close="chipClose(chip)"
            >
              <span
                  class="v-icon mr-1 category-card__color"
                  style="width: 16px;"
                  v-html="chip.icon.value"
                  v-if="chip.icon && chip.icon.svg"
              ></span>
              {{ chip.title }}
              <span v-if="chip.type === 'type' && stats[chip.id]">
                &nbsp; ({{ stats[chip.id] }})
              </span>
            </v-chip>
            <v-chip class="ma-1" color="transparent" @click:close="setAdvanced(false)" :close="filters.advanced" @click="setAdvanced(true)">Расширенный режим</v-chip>
          </div>
          <!--          </v-chip-group>-->
        </v-col>
      </v-row>
    </v-container>
    <v-dialog max-width="420" v-model="showLayerEdit" eager>
      <v-sheet class="overflow-y-auto" style="height: min(620px, 100vh)" >
        <span class="d-flex text-h6 font-weight-medium ma-3 my-2 mb-0">
          <span>
            Категории и типы данных
          </span>
          <v-spacer/>
          <v-btn icon  @click="showLayerEdit = false">
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>
        <OCLayerEditor v-if="showLayerEdit" v-model="ftypes" :options="filters"></OCLayerEditor>
      </v-sheet>
    </v-dialog>
    <v-container
        class="pa-0 mt-1"
        :style="($vuetify.theme.isDark || 'background:rgb(241, 243, 245); ') + ($vuetify.breakpoint.mdAndUp && 'border-radius: 12px')  "
        style="max-width:1280px"
    >
      <OCMapFilters
          ref="mapFilters"
          :filter="(data) => data.key !== 'zone' || $vuetify.breakpoint.smAndDown"
          @open="filterType = $event; $refs.searchBar.showFIlters = true"
          @input="$emit('update:filters', $event)" class="flex-md-wrap overflow-md-hidden overflow-x-auto px-4 pt-3" :color="$vuetify.theme.dark ? '' : 'white'" hide-toggle variant :value="filters"
      />
      <SearchBar
          ref="searchBar"
          :options="filters"
          @update:options="$emit('update:filters', $event)"
          :filters="dataFilters"
          :text_query.sync="query"
          :region.sync="region"
          :type-filters.sync="filterType"
      />
    </v-container>
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar";
import SecondaryButton from "../../../components/SecondaryButton.vue";
import TabChipGroup from "../../../components/TabChipGroup.vue";
import opencity from "@/service/opencity";
import useClassifier from "@/mixins/useClassifier";
import OCLayerEditor from "@/components/OCLayerEditor.vue";
import OCMapFilters from "@/components/OCMapFilters.vue";

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        query: ""
      })
    }
  },
  components: {
    OCMapFilters,
    OCLayerEditor,
    SearchBar,
    SecondaryButton,
    TabChipGroup
  },
  methods: {
    setAdvanced(val) {
      const prev = this.filters.advanced;
      this.filters.advanced = val;
      this.$emit('update:filters', this.filters);
      if (!prev && val) {
        // this.filters.dataFilters = {};
        // this.filters.category = null;
        // this.filters.type = null;
        this.showLayerEdit = true;
        // this.$emit('update:filters', this.filters);
      } else if (prev && !val) {
        this.filters.dataFilters = {};
        this.filters.types = null;
        this.filters.category = null;
        this.filters.type = null;
        this.$emit('update:filters', this.filters);
      }
    },
    chipClick(chip) {
      if (this.filters.advanced) {
        // this.showLayerEdit = true;
        this.filterType = chip.id;
        this.$refs.searchBar.showFIlters = true;
        return;
      }
      if (chip.type === "cat") {
        this.cat = chip;
      } else {
        this.type = chip;
      }
    },
    chipClose(chip) {
      if (chip.type === "layer") {
        this.ftypes = null;
        return;
      }
      if (chip.type === "cat") {
        this.cat = null;
        this.type = null;
      } else {
        this.type = null;
      }
    }
  },
  data: () => ({
    statsInternal: null,
    rawCategories: null,
    rawTypes: null,
    showLayerEdit: false,
    filterType: 0
  }),
  mixins: [useClassifier],
  computed: {
    layerCount() {
      return this.filters.types?.length;
    },
    ftypes: {
      get() {
        if (this.filters.types) return this.filters.types;
        return this.types?.map(el => el.id);
      },
      set(val) {
        this.filters.types = val;
        this.filters.category = null;
        this.filters.type = null;
        this.$emit("update:filters", this.filters);
      }
    },
    mapRoute() {
      return {
        path: "/service/moderation/map",
        query: this.$route.query
      };
    },
    cat: {
      get() {
        return this.classifier.cats.find(el => this.filters.category == el.id);
      },
      set(val) {
        this.filters.dataFilters = {};
        this.filters.category = val?.id;
        this.$emit("update:filters", this.filters);
      }
    },
    stats() {
      if (this.statsInternal) return this.statsInternal;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      opencity
          .get("/objects/stats")
          .then(res => res.json())
          .then(data => {
            this.statsInternal = data.reduce((o, el) => {
              o[el[0]] = el[1];
              return o;
            }, {});
          });
      return {};
    },
    type: {
      get() {
        return this.classifier.types.find(el => this.filters.type == el.id);
      },
      set(val) {
        this.filters.dataFilters = {};
        this.filters.type = val?.id;
        this.$emit("update:filters", this.filters);
      }
    },
    dataFilters() {
      return this.filters.dataFilters;
    },
    typeFilters: {
      get() {
        return 1;
      },
      set(val) {

      }
    },
    query: {
      get() {
        return this.filters.query;
      },
      set(val) {
        this.filters.query = val;
        this.$emit("update:filters", this.filters);
      }
    },
    region: {
      get() {
        return this.filters.zone;
      },
      set(val) {
        this.filters.zone = val;
        this.$emit("update:filters", this.filters);
      }
    },
    chips() {
      const { cat, type } = this;
      if (this.filters.advanced) return [];
      if (this.filters.types) {
        return this.classifier.types.filter(el => this.filters.types?.includes(el.id));
        // [{
        //   id: 0, title: this.filters.types.length + ' типов', type: 'layer'
        // }];
      }
      if (!cat) {
        return this.classifier.cats;
      } else if (!type) {
        const r = [cat];
        r.push(...this.types);
        return r;
      }
      return [cat, type];
    },
    types() {
      const { cat, type } = this;
      if (type) {
        return [type];
      }
      if (cat) {
        return this.classifier.types.filter(el => el.cat == cat.id);
      }
      return null;
    },
    classifier() {
      const cats = [...this.OCClassifier];

      cats.forEach(el => (el.type = "cat"));
      const types = cats.flatMap(
          el => el.objectTypes.map(t => ((t.cat = el.id), t)) //.filter(t => ((t.cat = el.id), t.active))
      );
      types.forEach(el => (el.type = "type"));

      return {
        cats,
        types
      };
    }
  }
};
</script>

<style>
.v-slide-group__content {
  padding: 4px 12px !important;
}
</style>
