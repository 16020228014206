<template>
  <v-list-item
    v-if="!item.isList"
    class="drawer-menu-item"
    link
    color="primary"
    :to="item.to"
    exact-path
  >
    <v-list-item-icon>
      <v-icon class="ma-auto">{{ item.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>

    <span class="mr-1" v-if="item.count">
      {{ item.count }}
    </span>
  </v-list-item>

  <v-list-group v-else :prepend-icon="item.icon" class="drawer-menu-item">
    <template v-slot:activator>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </template>

    <menu-item v-for="child in children" :key="child.title" :item="child" />
  </v-list-group>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    item: {
      default: {
        title: "Empty",
        icon: "mdi-border-none",
        isList: false
      }
    },
    showGroup: {
      default: true
    }
  },
  computed: {
    children() {
      return this.item?.children?.map(item => {
        item.icon = !this.showGroup ? "mdi-square-small" : "";
        return item;
      });
    }
  }
};
</script>

<style lang="scss">
// .v-navigation-drawer:not(.v-navigation-drawer--mini-variant)
//   .drawer-menu-item
//   .v-list-item,
// .v-navigation-drawer:not(.v-navigation-drawer--mini-variant)
//   .drawer-menu-item.v-list-item {
//   padding-left: 16px !important;
//   border-radius: 0 4px 4px 0 !important;
//   &:before {
//     border-radius: 0 4px 4px 0 !important;
//   }
// }
.drawer-menu-item {
  margin-bottom: 4px;
  position: relative;
  &.v-list-item--active {
    &::after {
      position: absolute;
      content: "";
      width: 3px;
      top: 0;
      bottom: 0;
      left: 0;
      background: var(--v-primary-base);
    }
    &::before {
      opacity: 0 !important;
    }
    &:hover::before {
      opacity: 0.12 !important;
    }
    // border-left: 3px solid var(--v-primary-base);
  }
}
</style>
