import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,_vm._g(_vm._b({staticStyle:{"height":"96px"},attrs:{"value":_vm.value}},'v-list-item',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?[_c('div',[_vm._v("#"+_vm._s(_vm.item.id))]),_c(VSpacer),_c(VProgressCircular,{attrs:{"indeterminate":"","width":"2"}})]:[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.objectType.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.location)}})],1),(_vm.item.media && _vm.item.media.length)?_c(VListItemAvatar,{staticClass:"rounded dynamic-saturation",attrs:{"tile":"","width":"100","size":"80","color":"grey"}},[_c(VImg,{attrs:{"src":_vm.getPicUrl(_vm.item.media[0])}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }