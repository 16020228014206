<template>
  <v-chip-group active-class="primary" mandatory>
    <v-chip
      :color="$vuetify.theme.isDark ? null : '#F5F6F8'"
      v-for="value in values"
      :key="value"
      v-text="value"
    />
  </v-chip-group>
</template>

<script>
export default {
  props: {
    values: {
      default: () => [],
      type: Array
    }
  }
};
</script>

<style scoped>
.v-chip {
  padding: 0 20px;
  margin-right: 12px;
}
</style>
