import UserSettings from "@/views/UserSettings/UserSettings.vue";
import Objects from "@/views/Objects";
import ObjectsModerationMap from "@/views/ObjectsModeration/ObjectsMap.vue";
import ObjectsModeration from "@/views/ObjectsModeration";
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import Home from "@/views/404.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: UserSettings
  },
  {
    path: "/service/objects",
    name: "Objects",
    component: Objects
  },
  {
    path: "/service/moderation/map",
    name: "ObjectsModerationMap",
    component: ObjectsModerationMap
  },
  {
    path: "/service/moderation",
    name: "ObjectsModeration",
    component: ObjectsModeration
  },
  {
    path: "/service/objects/create",
    name: "ObjectCreate",
    component: () => import("@/views/Objects/NewObject/NewObjectWizard.vue")
  },
  {
    path: "/service/objects/map",
    name: "ObjectsMap",
    component: () => import("@/views/Objects/ObjectsMap")
  },
  {
    path: "/service/objects/:id",
    name: "Object",
    component: () => import("@/views/Object/Object")
  },
  {
    path: "/people",
    name: "People",
    component: () => import("@/views/People/People")
  },
  {
    path: "/people/:id",
    name: "Person",
    component: () => import("@/views/Person/Person")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "*",
    component: Home
  }
];

export default routes;
