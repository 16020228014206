<template>
  <v-navigation-drawer
    :mini-variant="config.mini"
    cv-model="drawerShow"
    :value="config.show"
    @input="setValue"
    app
    :temporary="config.temporary"
    bottom
    mobile-breakpoint="0"
    class="drawer-z-index-1 test"
    floating
    :expand-on-hover="config.mini"
  >
    <template v-slot:prepend>
      <slot name="header" />
    </template>
    <drawer-menu class="drawer-menu" :shorten="config.mini" />
    <template v-slot:append>
      <div class="nav-drawer-footer">
        <div
          v-if="!config.mini"
          class="nav-drawer-footer__inner d-flex justify-center align-center body-2 "
        >
          <a>Помощь</a>
          <v-icon small>mdi-circle-small</v-icon>
          <a>Условия</a>
          <v-icon small>mdi-circle-small</v-icon>
          <v-menu offset-x>
            <template v-slot:activator="{ on }">
              <a v-on="on">
                Ещё <v-icon small class="ml-n1">mdi-chevron-down</v-icon>
              </a>
            </template>
            <v-list dense>
              <v-list-item link>Приватность</v-list-item>
              <v-list-item link>Cookies</v-list-item>
              <v-list-item link>Состояние</v-list-item>
              <v-list-item link>Разработчикам</v-list-item>
              <v-list-item link>О проекте</v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn icon tile block height="48px" @click="$emit('input', true)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import DrawerMenu from "./Menu";

export default {
  props: {
    value: Boolean
  },
  components: {
    DrawerMenu
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    autoSwitch() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    mdDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    config() {
      // console.log(this.$vuetify.breakpoint);
      if (this.isMobile) {
        if (this.value) {
          return {
            show: true,
            temporary: true,
            mini: false
          };
        } else {
          return {
            show: false,
            temporary: true,
            mini: false
          };
        }
      } else {
        if (this.value) {
          return {
            show: true,
            temporary: false,
            mini: false
          };
        } else {
          return {
            show: true,
            temporary: false,
            mini: true
          };
        }
      }
    }
  },
  methods: {
    setValue(event) {
      this.$emit("input", event);
    }
  },
  watch: {
    mdDown: {
      immediate: true,
      handler(val) {
        if (val) this.$emit("input", false);
      }
    }
  }
};
</script>

<style>
.v-navigation-drawer.drawer-z-index-1 {
  z-index: 5 !important;
}

/* .v-navigation-drawer:not(.v-navigation-drawer--mini-variant) .drawer-menu {
  padding-left: 0;
} */

.drawer-menu
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #434343 !important;
}

.drawer-menu .v-list-item__title {
  font-weight: normal !important;
}

.nav-drawer-footer {
  height: 48px;
  /* position: sticky;
  top: 100%; */
}

.theme--light .nav-drawer-footer {
  background: white;
}

.theme--dark .nav-drawer-footer {
  background: #363636;
}

.nav-drawer-footer__inner {
  height: 100%;
  overflow: hidden;
}

.v-application .nav-drawer-footer__inner a {
  word-break: keep-all;
  white-space: nowrap;
  color: currentColor;
  font-size: 13px;
  opacity: 0.7;
}

.v-application .nav-drawer-footer__inner a:hover {
  color: currentColor;
  opacity: 1;
}

.test .v-navigation-drawer__content {
  overflow-y: visible;
}
</style>
