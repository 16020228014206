<template>
  <div class="pa-3 pa-md-4">
    <span class="text-h4 font-weight-medium">Главная</span>
    <div class="col-md-6 pa-0 col-12">
      <router-link custom to="/service/objects/create">
        <CategoryCard color="#7cb342" class="pa-2 mt-4 justify-center">
          <v-icon class="category-card__color" size="36">mdi-plus</v-icon>
          <div class="text-center font-weight-bold">
            Добавить объект
          </div>
        </CategoryCard>
      </router-link>
      <div class="d-flex">
        <router-link custom to="/service/objects/map" class="w-100">
          <CategoryCard color="#7cb342" class="pa-2 my-4 w-100 mr-1 justify-center">
            <v-icon class="category-card__color" size="36"
              >mdi-map-outline</v-icon
            >
            <div class="text-center font-weight-bold">
              Карта объектов
            </div>
          </CategoryCard>
        </router-link>
        <router-link custom to="/service/objects/" class="w-100">
          <CategoryCard color="#7cb342" class="pa-2 my-4 ml-1 justify-center">
            <v-icon class="category-card__color" size="36"
              >mdi-format-list-bulleted</v-icon
            >
            <div class="text-center font-weight-bold">
              Список объектов
            </div>
          </CategoryCard>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PanelFrame from "@/components/PanelFrame";
import CategoryCard from "../components/CategoryCard.vue";

export default {
  name: "Home",
  created() {
    this.$emit("update:layout", PanelFrame);
  },
  components: { CategoryCard }
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
