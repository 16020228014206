<template>
  <v-btn
    class="secondary-btn"
    @click="$emit('click', $event)"
    v-bind="$attrs"
    color="primary"
    outlined
  >
    <slot />
  </v-btn>
</template>

<script>
export default {};
</script>

<style>
.secondary-btn .v-btn__content {
  font-weight: normal !important;
}
</style>
