import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"height":"400px"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VCardTitle,[_vm._v("Сохранить в...")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.showAddNew = false;
        _vm.$emit('close');}}},[_c(VIcon,[_vm._v("$close")])],1)],1),_c(VDivider),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c(VCardText,_vm._l((_vm.lists),function(list){return _c(VCheckbox,{key:list.id,attrs:{"hide-details":"","label":list.name,"indeterminate":list.someIncluded && !list.allIncluded},on:{"change":function($event){return _vm.selectHandler(list, $event)}},model:{value:(list.allIncluded),callback:function ($$v) {_vm.$set(list, "allIncluded", $$v)},expression:"list.allIncluded"}})}),1),_c(VDivider),_c(VCardActions,{staticClass:"pa-0"},[(!_vm.showAddNew)?_c(VBtn,{staticClass:"text-center",attrs:{"text":"","block":"","large":""},on:{"click":function($event){_vm.showAddNew = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Создать новый список ")],1):_c('div',{staticClass:"pa-3",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"px-4"},[_c(VTextField,{ref:"titleField",staticStyle:{"width":"100%"},attrs:{"maxlength":"100","counter":"100","outlined":"","dense":"","label":"Название","rules":_vm.titleRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.createListHandler}},[_vm._v("Создать")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }