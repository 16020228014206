<template>
  <div
    class="card_test--wrapper"
    :class="{ wrapper_covered: openModel, absolute: prominent }"
    @click.stop="openModel = false"
  >
    <div
      class="card_test"
      @click.stop
      :style="{ width: !openModel ? 0 : '300px' }"
    >
      <div :class="{ covered: openModel, 'pl-3': prominent }">
        <div>
          <slot v-if="openModel" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      default: false
    },
    prominent: {
      default: false
    }
  },
  computed: {
    openModel: {
      get() {
        return this.$props.open;
      },
      set(to) {
        this.$emit("update:open", to);
      }
    }
  }
};
</script>

<style lang="scss">
.card_test--wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  // background: rgba(33, 33, 33, 0);
  transition: background 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.wrapper_covered {
    pointer-events: all;
  }
  &.absolute {
    &.wrapper_covered {
      background: rgba(33, 33, 33, 0.33);
    }
    position: absolute;
  }
}

.card_test {
  float: right;
  pointer-events: all;
  height: 100%;
  transition-property: width, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  // overflow-x: hidden;
  // overflow-y: auto;
  // overflow: visible;
  > div {
    position: sticky;
    float: right;
    top: 48px;
    height: 100%;

    max-height: calc(100vh - 48px);
    overflow-x: hidden;
    // width: 100%;
    min-width: 300px;
    .v-card__title {
      word-break: keep-all;
    }
    visibility: hidden;
    transition-delay: 0.2s;
    transition-property: visibility !important;

    &.covered {
      visibility: visible;
      transition-delay: 0s;
      width: 100%;
      // transition-duration: 0.2s !important;
    }
    &.covered > div {
      transform: translateX(0%);
    }
    > div {
      transform: translateX(100%);
      transition-property: transform;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      width: 100%;
      height: 100%;
    }
    .v-card {
      height: 100%;
    }
  }
}
</style>
