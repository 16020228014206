<template>
  <v-combobox
    :value="evalValue"
    @input="onInput"
    :items="regions"
    return-object
    :placeholder="'Любой'"
    item-text="fullName"
    v-bind="$attrs"
    clearable
    ref="searchZone"
    type="search"
  >
    <template #prepend-item>
      <v-dialog
        scrollable
        max-width="420"
        v-model="showObjectDialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
      >
        <v-card height="420">
          <div class="d-flex pa-2 pl-4 flex-grow-0" dense elevation="0">
            <v-toolbar-title class="font-weight-medium"
              >Выбор пользователя</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="showObjectDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="px-4 overflow-hidden d-flex flex-column">
            <v-text-field
              :loading="loading"
              v-model="objectSearch"
              hide-details
              class="mb-2"
              clearable
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              placeholder="Поиск"
            ></v-text-field>
            <v-progress-linear indeterminate :active="loading" />
            <v-list
              class="flex-grow-1 rounded v-sheet--outlined"
              dense
              style="height: 100%; overflow-y: auto"
            >
              <v-list-item
                v-show="!loading"
                @click="selectUser(i)"
                v-for="i in users"
                :key="i.id"
                >{{ i.fullName }}</v-list-item
              >
              <div class="text-center" v-if="!loading && !users.length">
                Ничего не найдено
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-list-item
        dense
        @click="openSelectDialog"
        v-if="!$refs.searchZone || !$refs.searchZone.isSearching"
      >
        <v-list-item-avatar size="24">
          <v-icon size="18">mdi-map-search-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Найти пользователя
        </v-list-item-title>
      </v-list-item>
      <v-divider
        class="my-1"
        v-if="$refs.searchZone && !$refs.searchZone.isSearching"
      />
    </template>
  </v-combobox>
</template>
<script>
import opencity from "@/service/opencity";

export default {
  name: "UserSelector",
  props: {
    value: {}
  },
  data: () => ({
    regions: null,
    showObjectDialog: false,
    users: [],
    loading: false,
    objectSearch: "",
    currentObject: null
  }),
  created() {
    opencity.getMe().then(me => {
      me.fullName = "Мои объекты"
      this.regions = [me];
    })
    this.$watch(
      () => this.objectSearch,
      () => {
        this.fetchUsers();
      }
    );
  },
  computed: {
    evalValue() {
      if (!this.value) return null;
      if (opencity.userObject.id == this.value)
      {
        return opencity.userObject;
      }
      if (this.currentObject && this.currentObject.id == this.value) return this.currentObject;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      opencity.getPerson(this.value).then(person => this.currentObject = person);
      return "Загрузка...";
    },
  },
  methods: {
    onInput(val) {
      this.$emit("input", val?.id);
    },
    selectUser(o) {
      o.type = "object";
      o.name = o.title;
      this.showObjectDialog = false;
      this.$emit("input", o.id);
    },
    openSelectDialog() {
      this.showObjectDialog = true;
      this.objectSearch = "";
      this.fetchUsers();
    },
    async fetchUsers() {
      const types = [2, 3, 4, 31, 32, 33, 34, 35, 36, 17, 19];
      const promise = (this.loading = opencity.findPeople(this.objectSearch));
      const result = await (await promise)?.json();
      if (this.loading === promise) {
        this.loading = false;
        this.users = result ?? [];
      }
    }
  }
};
</script>

<style lang="scss"></style>
