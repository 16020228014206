<template>
  <div v-if="!hideToggle || size" class="d-flex map-wrapper ma-0 pa-1 oc-map-filters" style="gap: 4px;">
    <v-btn v-if="!hideToggle" :depressed="variant" :color="colorP" style="min-width: 56px;" @click="$emit('open', true)" class="px-3 rounded-lg">
      <v-badge offset-y="8" :value="size" :content="size.toString()">
        <v-icon>mdi-tune-variant</v-icon>
      </v-badge>
    </v-btn>
    <v-btn :depressed="variant" :color="colorP" @click="$emit('open', filter.type)" class="px-3 rounded-lg" :key="filter.title" v-for="filter in filters">
      <TextPromise :value="filter.title" />
      <v-icon right style="margin-left: 3px" @click.stop="close(filter)">mdi-close</v-icon>
    </v-btn>
  </div>
  <div class="d-none" v-else />
</template>

<script>
import {defaultFilterDefinitions} from "@/assets/filters";
import TextPromise from "@/components/TextPromise.vue";
import {processFilterObject} from "@/utils/filterOptions";

export default {
  components: {
    TextPromise
  },
  props: {
    value: {},
    variant: {
      type: Boolean,
      default: false
    },
    hideToggle: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ""
    },
    filter: {
      type: Function,
      default: () => true
    }
  },
  setup() {
    return {Promise};
  },
  watch: {
    size() {
      this.$emit('update:size', this.size);
    }
  },
  methods: {
    close(filter) {
      this.definitions[filter.key].reset(this.value);
      this.$emit("input", this.value);
    }
  },
  computed: {
    size() {
      return this.filters.length;
    },
    colorP() {
      if (this.color) return this.color;
      if (this.variant) {
        return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4';
      } else {
        return this.$vuetify.theme.dark ? '' : 'white';
      }
    },
    definitions() {
      return defaultFilterDefinitions;
    },
    filters() {
      return processFilterObject(this.value, this.definitions).filter(
        this.filter
      );
    }
  },
  name: "OCMapFilters"
}
</script>

<style scoped>
.oc-map-filters::-webkit-scrollbar {
  display: none;
}

.oc-map-filters  .mdi-close {
  opacity: 0.4;

}
.oc-map-filters  .mdi-close:hover {
  opacity: 1;
}
</style>